import styled, { css } from 'styled-components';
import { buttonBaseStyle, inputBaseStyle, inputDisabledStyle } from 'components/Form/Form.styles';
import colors from 'constants/colors';
import { BREAK_POINTS } from 'constants/sizes';

const { SMALL_DESKTOP } = BREAK_POINTS;
const {
  FOCUS,
  NEUTRAL_OUTLINE,
  PRIMARY,
  PRIMARY_ACTIVE,
  PRIMARY_OUTLINE,
  SECONDARY,
  ON_SECONDARY,
  ON_NEUTRAL_BACKGROUND,
  NEUTRAL_BACKGROUND,
  ON_PRIMARY,
} = colors;

const insetPaddingREM = '0.25rem';
const insetSizeREM = '1rem';
const inputLRPaddingREM = '0.5rem';
const inputPaddingREM = `0.125rem ${inputLRPaddingREM}`;
const borderRadiusREM = '6.25rem';
const inputHeightREM = '2rem';

const defaultButtonStyles = css`
  ${buttonBaseStyle};
  background-color: ${PRIMARY};
  border-radius: ${borderRadiusREM};
  box-shadow: 0 0 0 1px ${PRIMARY_OUTLINE};
  color: ${ON_PRIMARY};
  font-size: 0.75rem;
  height: ${inputHeightREM};
  padding: ${inputPaddingREM};
  width: auto;

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px ${FOCUS};
  }

  &:hover {
    background-color: ${PRIMARY_ACTIVE};
  }

  &:disabled {
    ${inputDisabledStyle}
  }
`;

const defaultInputStyles = css`
  ${inputBaseStyle};
  background-color: ${NEUTRAL_BACKGROUND};
  border-radius: ${borderRadiusREM};
  box-shadow: 0 0 0 1px ${NEUTRAL_OUTLINE};
  color: ${ON_SECONDARY};
  font-size: 0.625rem;
  height: ${inputHeightREM};
  padding: ${inputPaddingREM};
  width: 100%;

  &:hover {
    background-color: ${SECONDARY};
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px ${FOCUS};
    background-color: ${SECONDARY};
  }

  &:disabled {
    ${inputDisabledStyle}
  }

  &::placeholder {
    color: ${ON_SECONDARY};
  }
`;

const inputWithDataStyle = css`
  &:not(:focus):not(:disabled) {
    background-color: ${SECONDARY};
    box-shadow: 0 0 0 2px ${PRIMARY_OUTLINE};
    color: ${ON_PRIMARY};
  }
`;

const defaultSelectContainerStyles = css`
  position: relative;
`;

const defaultInsetStyles = css`
  ${inputBaseStyle};
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  height: ${insetSizeREM};
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${insetSizeREM};

  svg,
  path {
    fill: ${ON_SECONDARY};
  }

  svg {
    width: ${insetSizeREM};
    height: ${insetSizeREM};
  }
`;

export const SearchFilterForm = styled.form`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: ${insetPaddingREM};
  justify-content: left;
  margin: 1rem;
  @media (min-width: ${SMALL_DESKTOP}px) {
    margin: 1rem 0;
  }
`;

export const WrapContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: ${insetPaddingREM};
  justify-content: center;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  width: 10.85rem;
`;

export const SearchInput = styled.input`
  ${defaultInputStyles};
  padding-left: calc(${inputLRPaddingREM} + ${insetSizeREM} + ${insetPaddingREM});
  padding-right: 0.5rem;

  ${({ value }) => value && inputWithDataStyle}
`;

export const SearchIconContainer = styled.div`
  ${defaultInsetStyles};
  left: ${inputLRPaddingREM};
`;

export const ClearSearchButton = styled.button<{ $hasSearchTerm?: boolean }>`
  ${defaultInsetStyles}
  ${buttonBaseStyle}
  cursor: pointer;
  right: ${inputLRPaddingREM};

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px ${FOCUS};
  }

  &:hover {
    svg,
    path {
      fill: ${ON_PRIMARY};
    }
  }

  svg {
    transform: scale(0.9);
  }

  ${({ $hasSearchTerm }) => !$hasSearchTerm && `display: none;`}
`;

export const InstitutionNameContainer = styled.div`
  ${defaultSelectContainerStyles};
  width: 6rem;
`;

export const RequestingServicesContainer = styled.div`
  ${defaultSelectContainerStyles};
  width: 5.188rem;
`;

export const StudyTypeContainer = styled.div`
  ${defaultSelectContainerStyles};
  width: 5.188rem;
`;

export const DateTypeContainer = styled.div`
  ${defaultSelectContainerStyles};
  width: 5.75rem;
`;

export const StyledSelect = styled.select`
  ${defaultInputStyles};
  cursor: pointer;
  padding-right: calc(${inputLRPaddingREM} + ${insetSizeREM} + ${insetPaddingREM});

  ${({ value }) => value && inputWithDataStyle}
`;

export const DateInputContainer = styled.div`
  position: relative;
  width: 6rem;
`;

export const StyledDateInput = styled.input`
  ${defaultInputStyles};
  color-scheme: dark;
  letter-spacing: -0.3px;
  padding: ${inputPaddingREM};
  cursor: pointer;

  ${({ value }) => value && inputWithDataStyle}
`;

export const StyledDateInputHidden = styled.input`
  ${defaultInputStyles};
  color-scheme: dark;
  opacity: 0;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
`;

export const CalendarIconContainer = styled.div`
  ${defaultInsetStyles};
  cursor: pointer;
  right: ${inputLRPaddingREM};
  pointer-events: none;

  svg {
    transform: scale(0.8);
  }
`;

export const ChevronIconContainer = styled.div`
  ${defaultInsetStyles};
  cursor: pointer;
  right: ${inputLRPaddingREM};
  pointer-events: none;

  div {
    width: ${insetSizeREM};
    height: ${insetSizeREM};

    svg {
      transform: scale(1.8);
    }
  }
`;

export const PrimaryButton = styled.button`
  ${defaultButtonStyles};
`;

export const SecondaryButton = styled.button`
  ${defaultButtonStyles};
  background-color: ${NEUTRAL_BACKGROUND};
  box-shadow: 0 0 0 1px ${NEUTRAL_OUTLINE};
  color: ${ON_NEUTRAL_BACKGROUND};
  width: auto;

  &:hover {
    background-color: ${SECONDARY};
    color: ${ON_SECONDARY};
  }

  &:active,
  &:focus {
    color: ${ON_PRIMARY};
  }
`;

export const GhostButton = styled(SecondaryButton)`
  box-shadow: none;
  &:disabled {
    ${inputDisabledStyle}
  }
`;

export const InlineWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const Separator = styled.div`
  background-color: ${NEUTRAL_OUTLINE};
  height: 1.4375rem;
  width: 0.0625rem;
`;

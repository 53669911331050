import colors from 'constants/colors';
import IconDirection from 'enums/IconDirection';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';

const { ON_SECONDARY } = colors;
const { UP, DOWN, LEFT, RIGHT } = IconDirection;
const { ICON_ARROW } = ReactTestingLibraryDataProperties;

export const ROTATE = {
  [DOWN]: '0',
  [LEFT]: '90',
  [RIGHT]: '-90',
  [UP]: '180',
};

type ArrowType = Icon & { direction?: IconDirection };

const Arrow = ({ direction = DOWN, color = ON_SECONDARY }: ArrowType): JSX.Element => (
  <svg
    data-testid={ICON_ARROW}
    aria-hidden="true"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    style={{
      transform: `rotate(${ROTATE[direction]}deg)`,
    }}
  >
    <path
      d="M5.41475 10.3352C5.63442 10.5549 5.99058 10.5549 6.21025 10.3352L8.27275 8.27275C8.49242 8.05308 8.49242 7.69692 8.27275 7.47725C8.05308 7.25758 7.69692 7.25758 7.47725 7.47725L6.375 8.5795L6.375 2.0625C6.375 1.75184 6.12316 1.5 5.8125 1.5C5.50184 1.5 5.25 1.75184 5.25 2.0625L5.25 8.5795L4.14775 7.47725C3.92808 7.25758 3.57192 7.25758 3.35225 7.47725C3.13258 7.69692 3.13258 8.05308 3.35225 8.27275L5.41475 10.3352Z"
      fill={color}
    />
  </svg>
);

export default Arrow;

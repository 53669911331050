import { ReactComponent as ColumnTripleRegular } from '@fluentui/svg-icons/icons/column_triple_20_regular.svg';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Container from 'icons/Container';
import Icon from 'types/Icon';

const { ON_PRIMARY } = colors;

const { ICON_COLUMNS } = ReactTestingLibraryDataProperties;
const Columns = ({ size = 1, color = ON_PRIMARY }: Icon): JSX.Element => {
  return (
    <Container size={size} $testId={ICON_COLUMNS}>
      <ColumnTripleRegular role="img" fill={color} width={`${size}rem`} height={`${size}rem`} />
    </Container>
  );
};

export default Columns;

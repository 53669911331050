import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import getOrgDisplayData from 'api/queries/getOrgDisplayData';
import configuration from 'configuration';
import CustomErrors from 'enums/CustomErrors';
import tokenHelpers from 'helpers/cookies/tokenHelper';
import decodeAuthorizationPayload from 'helpers/decodeAuthorizationPayload';
import CustomError from 'helpers/error/CustomError';
import { ActionTypes } from 'slices/ActionTypes';
import { ctbViewerActions } from 'slices/ctbViewerSlice';
import { cxrViewerActions } from 'slices/cxrViewerSlice';
import { SettingsState, settingsActions } from 'slices/settingsSlice';
import { RunInitStudyEffectType, studyActions } from 'slices/studySlice';
import { isCtbStudyExternal, isCxrStudyExternal } from 'typeguards/studyExternal';
import loadStudyExternal from './helpers/loadStudyExternal';

const { UNEXPECTED_FRONTEND_ERROR } = CustomErrors;

const { runInitStudyEffect } = studyActions;

const initStudyEpic: Epic<ActionTypes> = (action$, state$) =>
  action$.pipe(
    ofType<ActionTypes, RunInitStudyEffectType>(runInitStudyEffect.type),
    switchMap(async ({ payload: ids }) => {
      const { accessionNumber, studyInstanceUid } = ids;

      // Pull name from auth token stored in cookies, and add to request headers
      const { idToken } = tokenHelpers.retrieveTokens();
      const { email, name } = decodeAuthorizationPayload(idToken);
      const studyAccessor = email || name || '';

      return {
        studyExternal: await loadStudyExternal(accessionNumber, studyInstanceUid, studyAccessor),
        ids,
      };
    }),
    switchMap(async ({ studyExternal, ids }) => {
      let displayData: SettingsState['displayData'] = state$?.value?.settings?.displayData;

      if (!displayData) {
        displayData = await getOrgDisplayData();
      }

      return { studyExternal, ids, displayData };
    }),
    mergeMap(({ studyExternal, ids, displayData }) => {
      const { isViewer } = configuration;
      let patientInformation = {};
      const { study } = studyExternal;
      const {
        type,
        studyDate,
        studyTime,
        description,
        patientAge,
        patientBirthDate,
        patientId,
        patientName,
        patientSex,
      } = study;

      let initModalityAction: ReturnType<
        typeof cxrViewerActions.runInitCxrStudyEffect | typeof ctbViewerActions.runInitCtbStudyEffect
      >;

      if (isViewer) {
        patientInformation = {
          patientAge,
          patientBirthDate,
          patientId,
          patientName,
          patientSex,
        };
      }

      const studyObj = {
        type,
        ids,
        studyDate,
        studyTime,
        description,
      };

      if (isCxrStudyExternal(studyExternal)) {
        initModalityAction = cxrViewerActions.runInitCxrStudyEffect(studyExternal);
      } else if (isCtbStudyExternal(studyExternal)) {
        initModalityAction = ctbViewerActions.runInitCtbStudyEffect(studyExternal);
      } else {
        throw new CustomError(UNEXPECTED_FRONTEND_ERROR);
      }

      return of(
        studyActions.setStudy(studyObj),
        studyActions.setPatientInformation(patientInformation),
        settingsActions.setOrgDisplayData(displayData),
        initModalityAction,
      );
    }),
  );

export default initStudyEpic;

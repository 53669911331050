import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ON_PRIMARY } = colors;
const { ICON_SHARE } = ReactTestingLibraryDataProperties;

const Share = ({ size = 1, color = ON_PRIMARY, fillOpacity = 1 }: Icon): JSX.Element => (
  <Container size={size} $testId={ICON_SHARE}>
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1172 5.99931H15.3135C15.9348 5.99931 16.4385 6.50299 16.4385 7.12431C16.4385 7.69386 16.0153 8.16455 15.4662 8.23904L15.3135 8.24931H10.1172C8.33089 8.24931 6.86872 9.63705 6.74997 11.3932L6.74219 11.6243V25.8743C6.74219 27.6606 8.12993 29.1228 9.88611 29.2415L10.1172 29.2493H24.3683C26.1546 29.2493 27.6168 27.8616 27.7355 26.1054L27.7433 25.8743V25.1276C27.7433 24.5063 28.247 24.0026 28.8683 24.0026C29.4378 24.0026 29.9085 24.4259 29.983 24.975L29.9933 25.1276V25.8743C29.9933 28.8807 27.6347 31.3362 24.667 31.4915L24.3683 31.4993H10.1172C7.1108 31.4993 4.65528 29.1408 4.49998 26.173L4.49219 25.8743V11.6243C4.49219 8.61792 6.85073 6.1624 9.81845 6.00711L10.1172 5.99931H15.3135H10.1172ZM21.7482 9.77909V5.62431C21.7482 4.68836 22.8084 4.18423 23.5286 4.70901L23.6519 4.81242L32.6438 13.4374C33.0635 13.8399 33.1017 14.4865 32.7584 14.9334L32.644 15.0611L23.6521 23.6889C22.9767 24.3369 21.8788 23.9208 21.7589 23.0377L21.7482 22.8771V18.7891L21.2329 18.8343C17.6334 19.2098 14.1833 20.8311 10.8611 23.7253C10.0824 24.4037 8.87775 23.7623 9.00581 22.7376C10.003 14.7583 14.1758 10.3603 21.2991 9.80856L21.7482 9.77909V5.62431V9.77909ZM23.9982 8.26228V10.8743C23.9982 11.4956 23.4945 11.9993 22.8732 11.9993C17.0631 11.9993 13.4621 14.5135 11.9063 19.7351L11.7877 20.1529L12.316 19.7977C15.6707 17.6051 19.1948 16.4993 22.8732 16.4993C23.4427 16.4993 23.9134 16.9225 23.9879 17.4717L23.9982 17.6243V20.2385L30.24 14.2494L23.9982 8.26228Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export default Share;

import { AuthScope } from '@annaliseai/api-specifications';

export type AuthorizationPayload = {
  aud: string;
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  scopes: AuthScope[];
};

const emptyScopes: AuthScope[] = [];
const emptyTokenValues = { aud: '', email: '', family_name: '', given_name: '', name: '', scopes: emptyScopes };

const decodeAuthorizationPayload = (jwt: string): AuthorizationPayload => {
  try {
    const jwtToArray = jwt.split('.');

    if (jwtToArray.length !== 3) return emptyTokenValues;

    const payload = jwtToArray[1];

    return JSON.parse(atob(payload));
  } catch {
    return emptyTokenValues;
  }
};

export default decodeAuthorizationPayload;

import styled from 'styled-components';
import colors from 'constants/colors';

const { PRIMARY, ON_PRIMARY } = colors;

const PrimaryButton = styled.button`
  padding: 0 1.5rem;
  background-color: ${PRIMARY};
  color: ${ON_PRIMARY};
  border-radius: 0.5em;
  border: 0;
  height: 100%;
  outline: none;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default PrimaryButton;

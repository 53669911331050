import { AppStrings } from 'constants/strings/strings.types';

const strings: AppStrings = {
  demo: {
    COMPATIBLE_SIZE_MESSAGE_1:
      'Sorry Annalise demo is not currently compatible with your screen resolution. The minimum supported resolution is ',
    COMPATIBLE_SIZE_MESSAGE_2: 'Please try again with a larger browser size or adjusting your browser zoom settings.',
    CONTACT_SUPPORT: 'Please contact support at ',
    EMAIL_SUPPORT: 'support@annalise.ai',
    HELP: 'Help',
    PUBLIC_HEADING: 'Try Annalise Web Demo',
    PUBLIC_SUBHEADING: 'Experience comprehensive AI for medical imaging',
    TITLE: 'Annalise Demo',
    TITLE_HELP_MODAL: 'Need help with the Annalise web demo?',
    TITLE_LOGGED_IN: 'Annalise Private Demo',
    TITLE_DISCLAIMER:
      'Annalise demo is not for clinical use. Do not use the Annalise demo or its output for any diagnostic or therapeutic use in humans. Annalise.ai require written consent for use in any publication or presentation.',
    MAIL_TO_HREF: `mailto:support@annalise.ai?subject=Annalise demo support`,
    NO_PERMISSION:
      'You don’t have the correct permissions to view this page, please contact your system administrator.',
    RESULTS_LIST: 'Uploads',
    TEXT_DISCLAIMER: 'Not a medical device. Not for clinical or research use.',
  },
  viewer: {
    COMPATIBLE_SIZE_MESSAGE_1:
      'Sorry Annalise viewer is not currently compatible with your screen resolution. The minimum supported resolution is ',
    COMPATIBLE_SIZE_MESSAGE_2: 'Please try again with a larger browser size or adjusting your browser zoom settings.',
    CONTACT_SUPPORT: 'Please contact support at ',
    EMAIL_SUPPORT: 'support@annalise.ai',
    HELP: 'Help',
    PUBLIC_HEADING: 'Try Annalise Web Viewer Beta',
    PUBLIC_SUBHEADING: 'Experience comprehensive AI for medical imaging',
    TITLE: 'Annalise Web Viewer',
    TITLE_HELP_MODAL: 'Need help with Annalise Web Viewer?',
    TITLE_LOGGED_IN: 'Annalise Web Viewer',
    TITLE_DISCLAIMER:
      'Annalise private beta is not for clinical use. Do not use the Annalise private beta or its output for any diagnostic or therapeutic use in humans. Annalise.ai require written consent for use in any publication or presentation.',
    MAIL_TO_HREF: `mailto:support@annalise.ai?subject=Annalise Web Viewer support`,
    NO_PERMISSION:
      'You don’t have the correct permissions to view this page, please contact your system administrator.',
    RESULTS_LIST: 'Results list',
    TEXT_DISCLAIMER: 'Not intended to provide direct diagnosis',
  },
};

export const PLACEHOLDER_DASH = '-';

export default strings;

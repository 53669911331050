enum SigningHeaders {
  X_ANNALISE_AI_APP_VERSION = 'x-annalise-ai-app-version',
  X_ANNALISE_AI_CLIENT_ID = 'x-annalise-ai-client-id',
  X_ANNALISE_AI_CLIENT_SECRET = 'x-annalise-ai-client-secret',
  X_ANNALISE_AI_FORWARD_PUBLIC = 'x-annalise-ai-forward-public',
  X_ANNALISE_AI_FORWARD_SAMPLE = 'x-annalise-ai-forward-sample',
  X_ANNALISE_AI_USERNAME = 'x-annalise-ai-username',
  X_ANNALISE_AI_TRANSLATION = 'Accept-Language',
}

export default SigningHeaders;

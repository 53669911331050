import { colors } from '@annaliseai/anna-design-tokens';
import React, { FC } from 'react';
import styled, { css, DataAttributes } from 'styled-components';
import IconDirection from 'enums/IconDirection';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { NEUTRAL_BACKGROUND, ON_NEUTRAL_BACKGROUND } = colors;
const { UP, LEFT, DOWN, RIGHT } = IconDirection;
const { ICON_CHEVRON_BOLD, ICON_CHEVRON_BOLD_SVG, ICON_CHEVRON_BOLD_PATH } = ReactTestingLibraryDataProperties;

const ROTATE = {
  [UP]: '-90',
  [LEFT]: '180',
  [DOWN]: '90',
  [RIGHT]: '0',
};

const SHADOW = {
  [UP]: '-1px 1px',
  [LEFT]: '-1px -1px',
  [DOWN]: '1px -1px',
  [RIGHT]: '1px 1px',
};

interface ChevronProps extends React.SVGAttributes<SVGElement> {
  direction?: IconDirection;
  shadow?: string;
  fill?: string;
  size?: number;
}

const Container = styled.div.attrs<DataAttributes>({
  'data-testid': ICON_CHEVRON_BOLD,
})<{ size: number }>`
  width: ${({ size }) => `${size}rem`};
  height: ${({ size }) => `${size}rem`};
`;

const Svg = styled.svg<{ direction: IconDirection; shadow: string }>`
  ${({ direction, shadow }) => css`
    transform: rotate(${ROTATE[direction]}deg);
    filter: drop-shadow(${SHADOW[direction]} 0px ${shadow});
  `}
`;

const ChevronBold: FC<ChevronProps> = ({
  direction = RIGHT,
  fill = ON_NEUTRAL_BACKGROUND,
  shadow = NEUTRAL_BACKGROUND,
  size = 1,
}) => (
  <Container size={size}>
    <Svg
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      direction={direction}
      data-testid={ICON_CHEVRON_BOLD_SVG}
      shadow={shadow}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.529 2.861c-.26.26-.26.683 0 .943L9.724 8l-4.195 4.196c-.26.26-.26.682 0 .942.26.26.682.26.942 0l4.667-4.666c.26-.26.26-.683 0-.943L6.471 2.86c-.26-.26-.682-.26-.942 0z"
        fill={fill}
        data-testid={ICON_CHEVRON_BOLD_PATH}
      />
    </Svg>
  </Container>
);

export default ChevronBold;

import { BytesRange } from '@annaliseai/api-specifications';
import { imageLoaderPrefixSeparator } from 'constants/imageLoaderPrefixesMapping';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import CornerstoneCtbSegmentSliceId from 'types/cornerstone/CornerstoneCtbSegmentSliceId';

const { WEB } = CornerstoneLoaderPrefixes;

const createCtbSegmentSliceId = (url: string, { start, end }: BytesRange): CornerstoneCtbSegmentSliceId =>
  `${WEB}${imageLoaderPrefixSeparator}${start}|${end}|${url}`;

export default createCtbSegmentSliceId;

import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ON_PRIMARY } = colors;
const { USER, USER_SOLID } = ReactTestingLibraryDataProperties;

export const User: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={USER}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.754 14a2.249 2.249 0 0 1 2.249 2.249v.575c0 .894-.32 1.76-.901 2.439C17.532 21.096 15.145 22 12 22c-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 0 1-.898-2.434v-.578a2.249 2.249 0 0 1 2.248-2.25h11.502zm0 1.5H6.252a.749.749 0 0 0-.748.749v.578c0 .535.19 1.053.539 1.46 1.253 1.468 3.219 2.214 5.957 2.214s4.706-.746 5.962-2.214a2.25 2.25 0 0 0 .541-1.463v-.575a.749.749 0 0 0-.749-.75zM12 2.005a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 1.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export const UserSolid: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={USER_SOLID}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.754 14a2.249 2.249 0 0 1 2.25 2.249v.918a2.75 2.75 0 0 1-.513 1.6C17.945 20.928 15.42 22 12 22c-3.422 0-5.945-1.072-7.487-3.236a2.75 2.75 0 0 1-.51-1.596v-.92a2.249 2.249 0 0 1 2.249-2.25h11.501zM12 2.005a5 5 0 1 1 0 10 5 5 0 0 1 0-10z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ON_PRIMARY } = colors;
const { ICON_STUDIES_SOLID } = ReactTestingLibraryDataProperties;

const StudiesSolid: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={ICON_STUDIES_SOLID}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M15.625 6.667a2.708 2.708 0 012.704 2.555l.004.153v6.25a2.709 2.709 0 01-2.554 2.704l-.154.005h-6.25a2.708 2.708 0 01-2.704-2.555l-.004-.154v-6.25A2.708 2.708 0 019.22 6.671l.154-.004zm-2.5-2.499c1.126 0 2.091.687 2.5 1.665h-6.25a3.542 3.542 0 00-3.542 3.542v6.252a2.71 2.71 0 01-1.662-2.347l-.004-.154v-6.25A2.708 2.708 0 016.72 4.172l.154-.004zm-2.5-2.5c1.083 0 2.018.636 2.451 1.555l.049.112h-6.25l-.177.004a3.547 3.547 0 00-3.365 3.537v6.251a2.71 2.71 0 01-1.662-2.347l-.004-.154v-6.25A2.708 2.708 0 014.22 1.672l.154-.004z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  </Container>
);

export default StudiesSolid;

import React, { FC, ReactElement } from 'react';
import styled, { css, DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import FindingIndicatorType from 'enums/FindingIndicatorType';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import {
  BulletPointIcon,
  LateralityBilateralIcon,
  LateralityLeftIcon,
  LateralityRightIcon,
  LocalisationMultiIcon,
  LocalisationNoneIcon,
  LocalisationSingleIcon,
} from 'icons';

const {
  NO_LOCALISATION,
  BULLET_POINT,
  BILATERAL_LOCALISATION,
  LEFT_LATERALITY_LOCALISATION,
  RIGHT_LATERALITY_LOCALISATION,
  MULTIPLE_IMAGES_LOCALISATION,
  SINGLE_IMAGE_LOCALISATION,
} = FindingIndicatorType;
const {
  FINDING_INDICATOR,
  ICON_BULLET_POINT,
  ICON_LATERALITY_BILATERAL,
  ICON_LATERALITY_LEFT,
  ICON_LATERALITY_RIGHT,
  ICON_LOCALISATION_MULTI,
  ICON_LOCALISATION_NONE,
  ICON_LOCALISATION_SINGLE,
} = ReactTestingLibraryDataProperties;

const { ON_PRIMARY, ON_NEUTRAL_BACKGROUND } = colors;

interface FindingIndicatorProps {
  findingIndicatorType: FindingIndicatorType;
  isActive: boolean;
  fill?: string;
}

const ICONS: Record<FindingIndicatorType, ReactElement> = {
  [NO_LOCALISATION]: <LocalisationNoneIcon data-testid={ICON_LOCALISATION_NONE} />,
  [BULLET_POINT]: <BulletPointIcon data-testid={ICON_BULLET_POINT} />,
  [LEFT_LATERALITY_LOCALISATION]: <LateralityLeftIcon data-testid={ICON_LATERALITY_LEFT} />,
  [RIGHT_LATERALITY_LOCALISATION]: <LateralityRightIcon data-testid={ICON_LATERALITY_RIGHT} />,
  [BILATERAL_LOCALISATION]: <LateralityBilateralIcon data-testid={ICON_LATERALITY_BILATERAL} />,
  [SINGLE_IMAGE_LOCALISATION]: <LocalisationSingleIcon data-testid={ICON_LOCALISATION_SINGLE} />,
  [MULTIPLE_IMAGES_LOCALISATION]: <LocalisationMultiIcon data-testid={ICON_LOCALISATION_MULTI} />,
};

const IconWrapperStyled = styled.div.attrs<DataAttributes>({ 'data-testid': FINDING_INDICATOR })<{
  fill: string;
}>`
  svg path {
    ${({ fill }) => css`
      fill: ${fill};
    `};
  }
`;

const FindingIndicator: FC<FindingIndicatorProps> = ({ findingIndicatorType, isActive, fill }) => (
  <IconWrapperStyled fill={fill ? fill : isActive ? ON_PRIMARY : ON_NEUTRAL_BACKGROUND}>
    {ICONS[findingIndicatorType]}
  </IconWrapperStyled>
);

export default FindingIndicator;

import { AuthScope, Status } from '@annaliseai/api-specifications';
import { CellContext, flexRender } from '@tanstack/react-table';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import ColumnSelect from 'components/ColumnSelect/ColumnSelect';
import Divider from 'components/Divider/Divider';
import LoadingIndicator from 'components/LoadingIndicator';
import ResultsPerPageSelector from 'components/ResultsPerPageSelector/ResultsPerPageSelector';
import ViewedFilter from 'components/ViewedFilter/ViewedFilter';
import WorklistPagination from 'components/WorklistPagination/WorklistPagination';
import WorklistRowActions from 'components/WorklistRowActions/WorklistRowActions';
import useWorklistTable from 'components/WorklistTable/useWorklistTable';
import {
  getArrowDirection,
  getColumnTooltip,
  isColumnSortable,
  isColumnSortActive,
  isProcessing,
} from 'components/WorklistTable/utils/WorklistTable.utils';
import { NO_RESULTS, RESULTS_PER_PAGE_OPTIONS } from 'components/WorklistTable/WorklistTable.constants';
import {
  Body,
  ButtonWrapper,
  Cell,
  ColumnHeading,
  ColumnHeadingSortButton,
  ColumnHeadingWrapper,
  FlexContainer,
  Head,
  HeaderRow,
  NoResultsContainer,
  NoResultsNotice,
  PaginationContainer,
  Row,
  rowStylingConfig,
  HeaderSortIconWrapper,
  Table,
  TableControls,
} from 'components/WorklistTable/WorklistTable.styles';
import { Columns } from 'components/WorklistTable/WorklistTypes';
import { isFetchFulfilled } from 'enums/FetchStates';
import { retrieveTokens } from 'helpers/cookies/tokenHelper';
import decodeAuthorizationPayload from 'helpers/decodeAuthorizationPayload';
import usePagination from 'hooks/usePagination';
import useStudiesSort from 'hooks/useStudiesSort';
import Arrow from 'icons/Arrow';
import ArrowUpDown from 'icons/ArrowUpDown';
import { selectStudies, selectStudiesFetchState } from 'selectors/studyList/selectStudyList';
import DisplayStudyInformation from 'types/DisplayStudyInformation';

const { IN_PROGRESS } = Status;

const WorklistTable = (): ReactElement => {
  const studiesFetchState = useSelector(selectStudiesFetchState);

  const usePaginationProps = usePagination();
  const { sortBy, direction, handleSort } = useStudiesSort();
  const { table, handleRowClick, toggleMenuOptions } = useWorklistTable();

  const studies = useSelector(selectStudies);
  const hasStudies = !!studies?.length;

  const storedTokens = retrieveTokens();
  const decodedToken = decodeAuthorizationPayload(storedTokens?.token);
  const isAdmin = decodedToken?.scopes?.find((scope: AuthScope) => scope === AuthScope.ADMIN);

  return (
    <>
      <Table>
        <Head>
          <HeaderRow>
            {table.getHeaderGroups().map(headerGroup =>
              headerGroup.headers.map(columnHeader => {
                const { meta, header, id } = columnHeader.column.columnDef;
                const isSortable = isColumnSortable(header as string);
                const isSortActive = isColumnSortActive(id, sortBy);
                const headerText = flexRender(header, columnHeader.getContext());
                return (
                  <ColumnHeading {...meta} key={id}>
                    {isSortable ? (
                      <ButtonWrapper>
                        <ColumnHeadingSortButton
                          onClick={handleSort(id)}
                          $isSortActive={isSortActive}
                          title={getColumnTooltip(id as keyof Columns, direction, isSortActive)}
                        >
                          {headerText}
                          <HeaderSortIconWrapper>
                            {isSortActive ? <Arrow direction={getArrowDirection(id, direction)} /> : <ArrowUpDown />}
                          </HeaderSortIconWrapper>
                        </ColumnHeadingSortButton>
                      </ButtonWrapper>
                    ) : (
                      <ColumnHeadingWrapper title={getColumnTooltip(id as keyof Columns)}>
                        {headerText}
                      </ColumnHeadingWrapper>
                    )}
                  </ColumnHeading>
                );
              }),
            )}
            {isAdmin && <ColumnHeading />}
          </HeaderRow>
        </Head>
        <Body>
          {table.getRowModel().rows.map(row => {
            const { accessionNumber, studyInstanceUid, priority, rank, status, error, patientName } = row.original;
            const studyIdentifiers = { accessionNumber, studyInstanceUid };
            const isClickable = !isProcessing(status);
            const hasPriorityFindings = !!priority && !!rank && rank in rowStylingConfig;

            return (
              <Row
                key={row.id}
                rank={hasPriorityFindings ? rank : undefined}
                $hasPriorityFindings={hasPriorityFindings}
                // CXR does not have a processing status, so if !status, we should consider it to be processing
                status={status ? status : IN_PROGRESS}
                onClick={(isClickable && (() => handleRowClick(error, studyIdentifiers))) || undefined}
              >
                {row.getVisibleCells().map(cell => {
                  const cellContext = cell.getContext();
                  const meta = cellContext.cell.column.columnDef.meta;
                  const hasProps = !!meta?.getProps;
                  // Typecasting here - TS having trouble comparing AugmentedColumnDef and ColumnDef - TS2321
                  const cellProps = hasProps
                    ? { ...meta.getProps(cellContext as CellContext<DisplayStudyInformation, unknown>) }
                    : {};
                  return (
                    <Cell {...cellProps} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cellContext)}
                    </Cell>
                  );
                })}
                {isAdmin && <WorklistRowActions accessionNumber={accessionNumber} patientName={patientName} />}
              </Row>
            );
          })}
        </Body>
      </Table>

      {!hasStudies && (
        <NoResultsContainer>
          {isFetchFulfilled(studiesFetchState) ? <NoResultsNotice>{NO_RESULTS}</NoResultsNotice> : <LoadingIndicator />}
        </NoResultsContainer>
      )}
      <TableControls>
        <FlexContainer>
          <ColumnSelect columns={toggleMenuOptions} />
          <Divider />
          <ResultsPerPageSelector options={RESULTS_PER_PAGE_OPTIONS} />
        </FlexContainer>
        <PaginationContainer>{hasStudies && <WorklistPagination {...usePaginationProps} />}</PaginationContainer>
        <ViewedFilter />
      </TableControls>
    </>
  );
};

export default WorklistTable;

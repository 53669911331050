import DisplayError from 'types/DisplayError';

const buildErrorMessage = (errorDetails: DisplayError): string => {
  let errorMessage = '';

  if (errorDetails?.paragraphs) {
    errorMessage = errorDetails.paragraphs
      .reduce((paragraphs, paragraph) => (paragraph ? `${paragraphs}${paragraph} ` : paragraphs), '')
      .trim();
  }

  if (errorDetails?.lastParagraph) {
    errorMessage = ` ${errorMessage} ${errorDetails?.lastParagraph}`;
  }

  return errorMessage.trim();
};

export default buildErrorMessage;

import { ReactComponent as MoreHorizontalFilled } from '@fluentui/svg-icons/icons/more_horizontal_20_filled.svg';
import { ReactComponent as MoreHorizontalRegular } from '@fluentui/svg-icons/icons/more_horizontal_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { BREADCRUMBS } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

export const Breadcrumbs: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={BREADCRUMBS}>
    <MoreHorizontalRegular fill={color} fillOpacity={fillOpacity} width="inherit" height="inherit" />
  </Container>
);

export const BreadcrumbsSolid: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={BREADCRUMBS}>
    <MoreHorizontalFilled fill={color} fillOpacity={fillOpacity} width="inherit" height="inherit" />
  </Container>
);

import { AuthScope } from '@annaliseai/api-specifications';
import { ABOUT_WEB_VIEWER_TEXT } from 'components/Menu/MenuLink/AboutModal/constants';
import { LINK_ANNALISE, LINK_PRIVACY_POLICY, LINK_TERMS_CONDITIONS } from 'constants/links';
import Routes from 'enums/Routes';
import showLinks from 'helpers/showLinks';
import About from 'icons/About';
import AboutSolid from 'icons/AboutSolid';
import { AnnaLogo, AnnaLogoSolid } from 'icons/AnnaLogo';
import { Brain, BrainSolid } from 'icons/Brain';
import { Lungs, LungsSolid } from 'icons/Lungs';
import SignOut from 'icons/SignOut';
import SignOutSolid from 'icons/SignOutSolid';
import Studies from 'icons/Studies';
import StudiesSolid from 'icons/StudiesSolid';
import { Swap, SwapSolid } from 'icons/Swap';
import ColumnTriple, { ColumnTripleSolid } from 'icons/TextColumn';
import { User, UserSolid } from 'icons/User';
import WindowSettings, { WindowSettingsSolid } from 'icons/WindowSettings';
import { authActions } from 'slices/authSlice';
import {
  ABOUT_TEXT,
  ADMIN_TEXT,
  BACK_TEXT,
  CTB_SAMPLE_TEXT,
  CXR_SAMPLE_TEXT,
  PRIVACY_POLICY_TEXT,
  RESULT_LIST_TEXT,
  SIGN_IN_TEXT,
  SIGN_OUT_TEXT,
  LEGAL_NOTICES_TEXT,
  UPLOADED_TEXT,
} from './constants';
import NavMenuConfig, { NavMenuConfigItem } from './NavItemConfig.types';

const { READ: READ_SCOPE, ADMIN: ADMIN_SCOPE } = AuthScope;
const { ADMIN, CTB_SAMPLE, CXR_SAMPLE, HOME, LIST, LOGIN } = Routes;

const checkIsPublicDemo: NavMenuConfigItem['shouldShow'] = ({ pathname, isCurrentlyLoggedIn }) =>
  pathname !== LOGIN && !isCurrentlyLoggedIn;

export const VIEWER_MENU_CONFIG: NavMenuConfig = [
  {
    type: 'ROUTE',
    Icon: WindowSettings,
    IconHover: WindowSettingsSolid,
    route: ADMIN,
    text: ADMIN_TEXT,
    shouldShow: ({ userScopes = [], pathname }) =>
      pathname !== ADMIN && userScopes.includes(ADMIN_SCOPE) && showLinks(),
  },
  {
    type: 'ROUTE',
    Icon: ColumnTriple,
    IconHover: ColumnTripleSolid,
    route: HOME,
    text: RESULT_LIST_TEXT,
    // In the unlikely event a user has NO scopes, we don't want them to see the link to the Results screen
    shouldShow: ({ userScopes = [], pathname }) => pathname !== HOME && userScopes.includes(READ_SCOPE) && showLinks(),
  },
  {
    type: 'MODAL',
    Icon: About,
    IconHover: AboutSolid,
    text: ABOUT_WEB_VIEWER_TEXT,
    shouldShow: () => true,
  },
];

export const DEMO_MENU_CONFIG: NavMenuConfig = [
  {
    type: 'ROUTE',
    Icon: Lungs,
    IconHover: LungsSolid,
    route: CXR_SAMPLE,
    text: CXR_SAMPLE_TEXT,
    shouldShow: checkIsPublicDemo,
  },
  {
    type: 'ROUTE',
    Icon: Brain,
    IconHover: BrainSolid,
    route: CTB_SAMPLE,
    text: CTB_SAMPLE_TEXT,
    shouldShow: checkIsPublicDemo,
  },
  {
    type: 'ROUTE',
    Icon: Studies,
    IconHover: StudiesSolid,
    route: LIST,
    text: UPLOADED_TEXT,
    shouldShow: ({ isCurrentlyLoggedIn }) => isCurrentlyLoggedIn,
  },
  {
    type: 'DIVIDER',
    shouldShow: () => true,
  },
  {
    type: 'LINK',
    Icon: About,
    IconHover: AboutSolid,
    href: LINK_TERMS_CONDITIONS,
    text: LEGAL_NOTICES_TEXT,
    shouldShow: () => true,
  },
  {
    type: 'LINK',
    Icon: About,
    IconHover: AboutSolid,
    href: LINK_PRIVACY_POLICY,
    text: PRIVACY_POLICY_TEXT,
    shouldShow: () => true,
  },
  {
    type: 'LINK',
    Icon: AnnaLogo,
    IconHover: AnnaLogoSolid,
    href: LINK_ANNALISE,
    text: ABOUT_TEXT,
    shouldShow: () => true,
  },
  {
    type: 'ROUTE',
    Icon: Swap,
    IconHover: SwapSolid,
    route: HOME,
    text: BACK_TEXT,
    shouldShow: ({ pathname }) => pathname === LOGIN,
  },
  {
    type: 'DIVIDER',
    shouldShow: () => true,
  },
  {
    type: 'ROUTE',
    Icon: User,
    IconHover: UserSolid,
    route: LOGIN,
    text: SIGN_IN_TEXT,
    shouldShow: checkIsPublicDemo,
  },
  {
    type: 'ACTION',
    Icon: SignOut,
    IconHover: SignOutSolid,
    action: authActions.logout,
    payload: { redirect: HOME },
    text: SIGN_OUT_TEXT,
    shouldShow: ({ isCurrentlyLoggedIn }) => isCurrentlyLoggedIn,
  },
];

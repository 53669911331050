import { useState } from 'react';
import { LoadingIndicator } from 'components';
import {
  ServiceCardGrid,
  ServicesLoadingIndicator,
  ErrorMessageContainer,
  ErrorMessage,
  AdminPortalHeader,
  AdminPortalSubHeader,
  BodyContainer,
  ServicesHeader,
  ServicesSubHeader,
} from 'screens/AdminPortal/AdminPortal.styles';
import ServiceCard from 'screens/AdminPortal/components/ServiceCard/ServiceCard';
import SubServiceCard from 'screens/AdminPortal/components/SubServiceCard/SubServiceCard';
import { ServiceType, ServicesProps } from 'types/AdminPortal';
import { ServiceGridRow, ServicesContainer, SubServiceContainer, SubServiceRow } from './Services.styles';

const AdminPortalHeaderText = 'Annalise Web Viewer';
const AdminPortalSubHeaderText = 'Service Overview';
const BackendStatusHeader = 'Backend System Status';
const IntegrationAdapterStatusHeader = 'Integration Adapter System Status';
const AdminPortalErrorMessage =
  'We were unable to get the statuses of your services at this time, please try again shortly. If the issue persists please contact us at';

const Services = (props: ServicesProps): JSX.Element => {
  const {
    infoState: { services, loading, error },
    statusLastUpdate,
  } = props;

  const [selectedService, setSelectedService] = useState<ServiceType>();

  const backendVersions = services?.backendVersions;
  const integrationAdapterVersions = services?.integrationAdapterVersions;
  const statusValues = [
    {
      type: 'backend',
      servicesHeader: BackendStatusHeader,
      servicesSubHeader: statusLastUpdate?.backend && new Date(statusLastUpdate?.backend).toLocaleString('en-AU'),
      versions: backendVersions,
    },
    {
      type: 'ia',
      servicesHeader: IntegrationAdapterStatusHeader,
      servicesSubHeader: statusLastUpdate?.ia && new Date(statusLastUpdate?.ia).toLocaleString('en-AU'),
      versions: integrationAdapterVersions,
    },
  ];

  return (
    <BodyContainer>
      {(backendVersions || integrationAdapterVersions) && (
        <div>
          <AdminPortalHeader>{AdminPortalHeaderText}</AdminPortalHeader>
          <AdminPortalSubHeader>{AdminPortalSubHeaderText}</AdminPortalSubHeader>
        </div>
      )}
      {(backendVersions || integrationAdapterVersions) &&
        statusValues.map(
          ({ type, servicesHeader, servicesSubHeader, versions }, index) =>
            versions && (
              <ServicesContainer key={`${type}-${index}`}>
                <div>
                  <ServicesHeader>{servicesHeader}</ServicesHeader>
                  <ServicesSubHeader>{`Last updated: ${servicesSubHeader ?? 'unknown'}`}</ServicesSubHeader>
                </div>

                <ServiceCardGrid data-testid={`${type}-service-list`}>
                  {versions.map((service: ServiceType, index: number) => (
                    <ServiceGridRow key={`${service}${index}`} active={selectedService === service}>
                      <ServiceCard
                        onClick={() => {
                          setSelectedService(selectedService === service ? undefined : service);
                        }}
                        name={service.name}
                        version={service.version}
                        runningSubComponentCount={service.runningSubComponentCount}
                        totalSubComponentCount={service.totalSubComponentCount}
                        active={selectedService === service}
                      />
                      <SubServiceContainer active={selectedService === service}>
                        {selectedService === service && (
                          <SubServiceRow>
                            {service.subComponents.map((subService, index) => (
                              <SubServiceCard
                                key={`${subService.name}${index}`}
                                name={`${subService.name} (${index + 1}/${service.totalSubComponentCount})`}
                                version={subService.version}
                                status={subService.status}
                                statusMessage={subService.statusMessage}
                              />
                            ))}
                          </SubServiceRow>
                        )}
                      </SubServiceContainer>
                    </ServiceGridRow>
                  ))}
                </ServiceCardGrid>
              </ServicesContainer>
            ),
        )}
      {loading && (
        <ServicesLoadingIndicator>
          <LoadingIndicator absoluteCentre={false} />
        </ServicesLoadingIndicator>
      )}
      {!loading && (error || (!backendVersions && !integrationAdapterVersions)) && (
        <ErrorMessageContainer>
          <ErrorMessage>
            {AdminPortalErrorMessage}
            <a href="mailto:support@annalise.ai"> support@annalise.ai </a>
          </ErrorMessage>
        </ErrorMessageContainer>
      )}
    </BodyContainer>
  );
};

export default Services;

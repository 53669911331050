import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonSize, ButtonVariant } from 'components/Buttons/Button/Button';
import ButtonText from 'components/Buttons/ButtonText/ButtonText';
import { RESULTS_PER_PAGE_SELECTOR_ATTRS, RESULTS_PER_PAGE_TEXT } from 'components/ResultsPerPageSelector/constants';
import ResultsPerPageContainer from 'components/ResultsPerPageSelector/ResultsPerPageSelector.styles';
import { ResultsPerPagePropType } from 'components/ResultsPerPageSelector/ResultsPerPageSelector.types';
import { PaginationCurrentDiv } from 'components/Table';
import { cookieExpiryOption } from 'components/WorklistTable/WorklistTable.constants';
import CookieKeys from 'enums/CookieKeys';
import Limits from 'enums/Limits';
import Pages from 'enums/Pages';
import { storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import { selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';

const { RESULTS_PER_PAGE_SELECTION } = CookieKeys;
const { ICON, SMALL } = ButtonSize;
const { DEFAULT } = ButtonVariant;
const { PAGE_1 } = Pages;
const { RESULTS_10 } = Limits;

const { setQuery } = studyListActions;

const ResultsPerPageSelector: FC<ResultsPerPagePropType> = ({
  options,
  size = SMALL,
  variant = DEFAULT,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { limit = RESULTS_10 } = useSelector(selectStudiesQuery);
  const [currentOption, setCurrentOption] = useState(limit);

  useEffect(() => {
    setCurrentOption(limit);
  }, [limit]);

  const handleSelect = (option: number) => () => {
    dispatch(setQuery({ limit: option, page: PAGE_1 }));
    storeUserSettings(RESULTS_PER_PAGE_SELECTION, { limit: option }, cookieExpiryOption);
  };

  return (
    <ResultsPerPageContainer range={options.length} {...RESULTS_PER_PAGE_SELECTOR_ATTRS}>
      {options.map(option => {
        if (option === currentOption) {
          return (
            <PaginationCurrentDiv
              size={size}
              key={`${option}-results-per-page`}
              title={`${option} ${RESULTS_PER_PAGE_TEXT}`}
              aria-label={`${option} ${RESULTS_PER_PAGE_TEXT}`}
            >
              {option}
            </PaginationCurrentDiv>
          );
        }
        return (
          <Button
            onClick={handleSelect(option)}
            key={`${option}-results-per-page`}
            size={ICON}
            variant={variant}
            title={`${option} ${RESULTS_PER_PAGE_TEXT}`}
            aria-label={`${option} ${RESULTS_PER_PAGE_TEXT}`}
          >
            <ButtonText size={size}>{option}</ButtonText>
          </Button>
        );
      })}
    </ResultsPerPageContainer>
  );
};

export default ResultsPerPageSelector;

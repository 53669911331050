import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import FontIcon from './FontIcon';

const { FILE_PLACEHOLDER } = ReactTestingLibraryDataProperties;

const { ON_SECONDARY } = colors;

const Container = styled.div`
  color: ${ON_SECONDARY};
  font-size: 2rem;
`;

const FilePlaceholder: FC = () => (
  <Container data-testid={FILE_PLACEHOLDER}>
    <FontIcon icon={faPlusCircle} />
  </Container>
);

export default FilePlaceholder;

import { ReactElement } from 'react';
import { SIGN_OUT_TEXT } from 'components/Nav/constants';
import SignOutButton from 'components/Nav/SignOut/SignOut.styles';
import keycloak from 'keycloak';

const SignOut = (): ReactElement => {
  const handleSignOutOnClick = () => keycloak.doLogout();
  return <SignOutButton onClick={handleSignOutOnClick}>{SIGN_OUT_TEXT}</SignOutButton>;
};

export default SignOut;

import React, { FC } from 'react';
import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { getRemainingFreeStudies, MAX_FREE_STUDIES } from 'helpers/limitationHelper';

const { UPLOADS_REMAINING_CONTAINER } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY, ON_NEUTRAL_BACKGROUND } = colors;

const UploadsContainer = styled.div.attrs<DataAttributes>({ 'data-testid': UPLOADS_REMAINING_CONTAINER })`
  color: ${ON_NEUTRAL_BACKGROUND};
  text-align: center;
  font-size: 0.9rem;
`;

const Counter = styled.span`
  color: ${ON_PRIMARY};
`;

const UploadsRemaining: FC = () => (
  <UploadsContainer>
    <Counter>{`${getRemainingFreeStudies()}/${MAX_FREE_STUDIES}`}</Counter>
    {' uploads left'}
  </UploadsContainer>
);

export default UploadsRemaining;

import styled from 'styled-components';
import colors from 'constants/colors';
import { FormContainerProps } from './LoginScreen.types';

const { PRIMARY, PRIMARY_CONTAINER } = colors;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

export const BodyBackground = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: ${PRIMARY};
  background-image: url('/static/images/background.jpg');
  background-size: cover;
  background-position: 50%;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto 8em;
  height: 21rem;
`;

export const Intro = styled.div`
  font-size: 3rem;
  width: 48rem;
  color: ${PRIMARY_CONTAINER};
`;

export const FormContainer = styled.form<FormContainerProps>`
  display: flex;
  ${({ disabled }) => (disabled ? 'opacity: 0.7;' : '')}
`;

export const TextFieldContainer = styled.div`
  height: 3rem;
  line-height: 3rem;
  width: 24rem;
  margin-right: 1rem;
`;

export const ButtonContainer = styled.div`
  height: 3rem;
  min-width: 6rem;
`;

import { cloudArrowUp } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/Button';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import Dropzone from 'components/Dropzone';
import Header from 'components/Header';
import PrimaryButton from 'components/PrimaryButton';
import colors from 'constants/colors';
import demoDisclaimerProps from 'constants/demoDisclaimerProps';
import errorsMapping from 'constants/errorsMapping';
import { BREAK_POINTS } from 'constants/sizes';
import CustomErrors from 'enums/CustomErrors';
import LoginStates from 'enums/LoginStates';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Routes from 'enums/Routes';
import isEmptyFile from 'helpers/file/isEmptyFile';
import newEmptyFile from 'helpers/file/newEmptyFile';
import { validateFile, validateFiles, validateFilesCount } from 'helpers/fileHelper';
import { isLoggedIn } from 'helpers/limitationHelper';
import { FooterContainer as ResponsiveFooterContainer, Text } from 'layouts/ResponsiveContainers';
import { errorActions } from 'slices/errorSlice';
import { fileActions } from 'slices/fileSlice';
import { RootState } from 'slices/RootState';
import LocationState from 'types/LocationState';
import { ValidationResult } from 'types/ValidationResult';

const { DROPZONE_CONTAINER } = ReactTestingLibraryDataProperties;
const { SESSION_EXPIRED_ERROR } = CustomErrors;
const { LOGIN, STAGE, LIST } = Routes;
const { FULFILLED } = LoginStates;
const { IPAD_MINI_BREADTH, IPAD_PRO_LENGTH } = BREAK_POINTS;
const { SECONDARY, ON_SECONDARY, ON_PRIMARY } = colors;

const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0;
  grid-template-areas:
    'nav'
    'main';
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 8rem;
  flex-direction: column;
  grid-area: main;
`;

const Title = styled.div`
  font-size: 1.2rem;
  margin: 4rem auto;
  max-width: ${IPAD_PRO_LENGTH}px;
  width: 100%;
`;

const MiddleBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem auto 4rem;
  max-width: ${IPAD_PRO_LENGTH}px;
  width: 100%;

  @media (min-width: ${IPAD_PRO_LENGTH}px) {
    flex-direction: row;
    margin: 4rem auto;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  margin-bottom: 4rem;
  flex-direction: column;

  @media (min-width: ${IPAD_PRO_LENGTH}px) {
    margin-right: 5rem;
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  font-size: 2.6rem;
  margin-bottom: 2rem;
`;

const SubDescription = styled.div`
  color: ${ON_SECONDARY};
  font-size: 1.25rem;
`;

const DropzonesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 2.25rem;
    margin: 0 auto;
  }

  margin: 0;
`;

const DropzoneContainer = styled.div`
  padding: 0;
  background-color: ${SECONDARY};
  border-radius: var(--dropzone--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 6rem;
  width: 30rem;
  margin: 0.3rem 0 0.3rem 0;

  svg {
    font-size: 1.5rem;
  }

  input {
    background: red;
  }

  @media (max-width: ${IPAD_MINI_BREADTH}px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 8rem;
`;

type UploadButtonContainerProps = {
  disabled: boolean;
};

const UploadButtonContainer = styled.div<UploadButtonContainerProps>`
  height: 3rem;
  margin: auto;
  ${({ disabled }) => (disabled ? 'opacity: 0.7;' : '')}
`;

const CancelButtonContainer = styled.div`
  align-self: flex-end;
  height: 3rem;
`;

const ButtonIconContainer = styled.span`
  margin-right: 1rem;
`;

const IconContainer = styled.div`
  margin-right: 0.5rem;
`;

const FilePlaceholderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;

const TextBlock = styled(Text)`
  display: flex;
`;

const dropzoneIndices = [0, 1, 2];

const StageScreen: FC = () => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState<File[]>(dropzoneIndices.map(_ => newEmptyFile()));
  const { loginState } = useSelector(({ auth }: RootState) => auth);

  const checkPrivateUserStatus = (): void => {
    const privateUserWithExpiredCredentials = loginState === FULFILLED && !isLoggedIn();
    if (privateUserWithExpiredCredentials) {
      dispatch(errorActions.setError(errorsMapping[SESSION_EXPIRED_ERROR]));
      dispatch(
        push<LocationState>(LOGIN, {
          redirect: STAGE,
        }),
      );
    }
  };

  const onFinish = async (files: File[]) => {
    checkPrivateUserStatus();
    const validationResultFilesCount = await validateFilesCount(files);
    if (validationResultFilesCount.error) {
      dispatch(errorActions.setError(validationResultFilesCount.error));
      return;
    }

    const validationResultFiles = await validateFiles(files);
    if (validationResultFiles.error) {
      dispatch(errorActions.setError(validationResultFiles.error));
      return;
    }

    dispatch(fileActions.runSetFilesEffect(files));
  };

  const validateOnChangeFile = async (index: number, file: File): Promise<ValidationResult<File>> => {
    const validationResult = await validateFile(index, file);

    if (validationResult.error) {
      dispatch(errorActions.setError(validationResult.error));
    }

    return validationResult;
  };

  const validateOnChangeFiles = (files: File[]): ValidationResult<File[]> => {
    const validationResult = validateFiles(files);

    if (validationResult.error) {
      dispatch(errorActions.setError(validationResult.error));
    }

    return validationResult;
  };

  const onUploadClick = async () => {
    await onFinish(files);
  };

  const onCancelClick = async () => {
    dispatch(push(LIST));
  };

  const onlyEmptyFiles = (files: File[]) => files.filter(file => !isEmptyFile(file)).length === 0;

  return (
    <Container>
      <Header>
        <CancelButtonContainer>
          <Button onClick={onCancelClick}>
            <ButtonIconContainer>
              <FontAwesomeIcon icon={faTimes} />
            </ButtonIconContainer>
            Cancel
          </Button>
        </CancelButtonContainer>
      </Header>

      <BodyContainer>
        <Title>Add new study (CXR only)</Title>
        <MiddleBodyContainer>
          <DescriptionContainer>
            <Description>
              Upload a new study with 1 or 2 frontal images (AP/PA) and an optional lateral image.
            </Description>
            <SubDescription>Detect up to 124 clinical findings</SubDescription>
          </DescriptionContainer>
          <DropzonesContainer>
            {dropzoneIndices.map(index => (
              <DropzoneContainer key={index} data-testid={DROPZONE_CONTAINER}>
                <Dropzone
                  index={index}
                  validateOnChangeFiles={validateOnChangeFiles}
                  validateOnChangeFile={validateOnChangeFile}
                  files={files}
                  setFiles={setFiles}
                  contentOnHover={
                    <>
                      <IconContainer>
                        <IconWrapper fill={ON_PRIMARY} svg={cloudArrowUp} size={1.25} />
                      </IconContainer>
                      <Text>Drop image here</Text>
                    </>
                  }
                  contentOnLoaded={
                    <>
                      <IconContainer>
                        <IconWrapper fill={ON_PRIMARY} svg={cloudArrowUp} size={1.25} />
                      </IconContainer>
                      <Text>Image successfully loaded</Text>
                    </>
                  }
                >
                  <FilePlaceholderContainer>
                    <IconContainer>
                      <IconWrapper fill={ON_SECONDARY} svg={cloudArrowUp} size={1.25} />
                    </IconContainer>
                    <TextBlock>
                      <Text>
                        Drag and drop or <u>browse files.</u>&nbsp;
                      </Text>
                      <Text>{!!index ? 'One FRONT or LAT image.' : 'One FRONT image only.'}</Text>
                    </TextBlock>
                  </FilePlaceholderContainer>
                </Dropzone>
              </DropzoneContainer>
            ))}
          </DropzonesContainer>
        </MiddleBodyContainer>
      </BodyContainer>
      <FooterContainer>
        <UploadButtonContainer disabled={onlyEmptyFiles(files)}>
          <PrimaryButton onClick={onUploadClick} disabled={onlyEmptyFiles(files)}>
            <ButtonIconContainer>
              <FontAwesomeIcon icon={faCheck} />
            </ButtonIconContainer>
            Confirm Upload
          </PrimaryButton>
        </UploadButtonContainer>
      </FooterContainer>
      <ResponsiveFooterContainer>
        <Disclaimer {...demoDisclaimerProps} />
      </ResponsiveFooterContainer>
    </Container>
  );
};

export default StageScreen;

import { ReactComponent as ArrowExitRegular } from '@fluentui/svg-icons/icons/arrow_exit_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ON_PRIMARY } = colors;
const { ICON_SIGN_OUT } = ReactTestingLibraryDataProperties;

const SignOut: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={ICON_SIGN_OUT}>
    <ArrowExitRegular fill={color} fillOpacity={fillOpacity} />
  </Container>
);

export default SignOut;

import Keycloak, { KeycloakPkceMethod, KeycloakInitOptions } from 'keycloak-js';
import configuration from 'configuration';
import CookieKeys from 'enums/CookieKeys';
import tokenHelpers from 'helpers/cookies/tokenHelper';
import { removeUserSettings } from 'helpers/cookies/userSettingsHelper';
const { SEARCH_SELECTION, VIEWED_SELECTION } = CookieKeys;

export const _keyCloak = new Keycloak(configuration?.authConfig);

export const TOKEN_CHECK_AHEAD_EXPIRY = 5;

export const init = (initOptions?: KeycloakInitOptions): Promise<boolean> => {
  const { token, refreshToken, idToken } = tokenHelpers.retrieveTokens();

  const initConfig: KeycloakInitOptions = {
    scope: 'openid',
    token,
    refreshToken,
    idToken,
    pkceMethod: 'S256' as KeycloakPkceMethod,
    checkLoginIframe: false,
    enableLogging: true,
    onLoad: 'login-required',
    ...initOptions,
  };

  return _keyCloak.init(initConfig);
};

_keyCloak.onAuthSuccess = (): void => {
  const { token = '', refreshToken = '', idToken = '' } = _keyCloak;
  tokenHelpers.storeTokens({ token, refreshToken, idToken });
};

_keyCloak.onAuthLogout = (): void => {
  tokenHelpers.removeTokens();
  removeUserSettings(SEARCH_SELECTION);
  removeUserSettings(VIEWED_SELECTION);
  _keyCloak.clearToken();
};

export const doUpdate = _keyCloak.updateToken;

export const doLogin = _keyCloak.login;

export const doLogout = (): void => {
  _keyCloak.logout({ redirectUri: window.location.origin });
};

export const isLoggedIn = (): boolean => !!_keyCloak.token;

export const getToken = (): string | undefined => _keyCloak.token;

const keycloak = {
  _keyCloak,
  init,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  doUpdate,
};

export default keycloak;

import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSubtitle, ModalTitle } from 'components/Menu/MenuLink/AboutModal/AboutModal.styles';
import { AboutModalProps } from 'components/Menu/MenuLink/AboutModal/AboutModal.types';
import {
  ABOUT_WEB_VIEWER_MODAL_SUBTITLE_TEXT,
  ABOUT_WEB_VIEWER_MODAL_TITLE_TEXT,
  UDI_WEB_VERSION,
  VERSION_ENTERPRISE_SUBHEADER,
  VERSION_WEB_SUBHEADER,
} from 'components/Menu/MenuLink/AboutModal/constants';
import DocumentationPanel from 'components/Menu/MenuLink/AboutModal/DocumentationPanel';
import InfoPanel from 'components/Menu/MenuLink/AboutModal/InfoPanel';
import LogosPanel from 'components/Menu/MenuLink/AboutModal/LogosPanel';
import VersionPanel from 'components/Menu/MenuLink/AboutModal/VersionPanel';
import MenuItemModal from 'components/Modals/MenuItemModal';
import { PLACEHOLDER_DASH } from 'constants/strings';
import selectVersions from 'selectors/viewer/selectVersions';
import { infoActions } from 'slices/infoSlice';
import { VersionNames } from 'types/VersionNames';

const AboutModalComponent = ({ modalOpen, onClose }: AboutModalProps): ReactElement => {
  const { ANNALISE_BACKEND_UDI, ANNALISE_BACKEND_VERSION } = VersionNames;
  const { REACT_APP_VERSION } = process.env;
  const dispatch = useDispatch();
  const versions = useSelector(selectVersions);

  useEffect(() => {
    !versions && dispatch(infoActions.fetchVersions());
  }, [dispatch, versions]);

  const udiEnterprise = versions?.[ANNALISE_BACKEND_UDI] || PLACEHOLDER_DASH;
  const versionEnterprise = versions?.[ANNALISE_BACKEND_VERSION] || PLACEHOLDER_DASH;

  return (
    <MenuItemModal show={modalOpen} onClose={onClose}>
      <ModalTitle>{ABOUT_WEB_VIEWER_MODAL_TITLE_TEXT}</ModalTitle>
      <ModalSubtitle>{ABOUT_WEB_VIEWER_MODAL_SUBTITLE_TEXT}</ModalSubtitle>
      <VersionPanel
        subheader={VERSION_WEB_SUBHEADER}
        udi={UDI_WEB_VERSION}
        version={REACT_APP_VERSION || PLACEHOLDER_DASH}
      />
      <VersionPanel subheader={VERSION_ENTERPRISE_SUBHEADER} udi={udiEnterprise} version={versionEnterprise} />
      <DocumentationPanel />
      <InfoPanel />
      <LogosPanel />
    </MenuItemModal>
  );
};

export default AboutModalComponent;

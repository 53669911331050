import { ReactComponent as RowTripleFilled } from '@fluentui/svg-icons/icons/row_triple_20_filled.svg';
import { ReactComponent as RowTripleRegular } from '@fluentui/svg-icons/icons/row_triple_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ICON_TEXT_COLUMN, ICON_TEXT_COLUMN_SOLID } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

const ColumnTriple: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={ICON_TEXT_COLUMN}>
    <RowTripleRegular fill={color} fillOpacity={fillOpacity} />
  </Container>
);

export default ColumnTriple;

export const ColumnTripleSolid: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={ICON_TEXT_COLUMN_SOLID}>
    <RowTripleFilled fill={color} fillOpacity={fillOpacity} />
  </Container>
);

import CustomErrors from 'enums/CustomErrors';
import DisplayErrorLastParagraph from 'enums/DisplayErrorLastParagraph';
import DisplayErrorParagraphs from 'enums/DisplayErrorParagraphs';
import DisplayErrorTitles from 'enums/DisplayErrorTitles';
import { MAX_FREE_STUDIES } from 'helpers/limitationHelper';
import DisplayError from 'types/DisplayError';

const {
  ANOTHER_FILE,
  ANOTHER_STUDY,
  DIFFERENT_STUDY,
  MORE_UPLOADS_SUPPORT,
  REFRESH_PAGE,
  REFRESH_PAGE_SELECT_STUDY,
  SUPPORT,
  SUPPORT_WITH_ERROR_CODE,
} = DisplayErrorLastParagraph;
const { STUDY_DELETE_NOT_PROCESSED } = DisplayErrorParagraphs;
const { STUDY_DELETE } = DisplayErrorTitles;

const TRY_AGAIN = 'Please try again.';

const errorsMapping: Record<CustomErrors, DisplayError> = {
  J001: {
    title: 'Invalid file type.',
    errorId: 'J001',
    paragraphs: ['Annalise CXR currently only supports the following types:'],
    enumeration: ['CXR DICOM images', 'PNG images', 'JPEG images'],
    lastParagraph: ANOTHER_FILE,
  },
  J002: {
    title: 'Image file size too large.',
    errorId: 'J002',
    paragraphs: ['There is a 100MB file size limit on each image.'],
    lastParagraph: ANOTHER_FILE,
  },
  J003: {
    title: 'Image resolution too low.',
    errorId: 'J003',
    paragraphs: ['Image resolution needs to be greater than 1024 x 1024 pixels.'],
    lastParagraph: ANOTHER_FILE,
  },
  J004: {
    title: 'Modality not supported.',
    errorId: 'J004',
    paragraphs: ['Annalise currently only supports the following modalities:'],
    enumeration: ['Computed Radiography (CR)', 'Digital Radiography (DX)'],
    lastParagraph: ANOTHER_STUDY,
  },
  J005: {
    title: 'Unsupported DICOM transfer syntax.',
    errorId: 'J005',
    paragraphs: ['Annalise CXR currently only supports the following DICOM transfer syntax:'],
    enumeration: [
      'JPEG 2000 Lossless (1.2.840.10008.1.2.4.90)',
      'Explicit VR Little Endian (1.2.840.10008.1.2.1)',
      'Implicit VR Little Endian (1.2.840.10008.1.2)',
      'JPEG Lossless First Order (1.2.840.10008.1.2.4.70)',
      'JPEG Lossless (1.2.840.10008.1.2.4.57)',
    ],
    lastParagraph: ANOTHER_FILE,
  },
  J006: {
    title: 'Unsupported DICOM file.',
    errorId: 'J006',
    paragraphs: ['The following fields are required to be present in DICOM header:'],
    enumeration: [
      'Bits Allocated Attribute',
      'Bits Stored Attribute',
      'High Bit Attribute',
      'Photometric Interpretation Attribute',
    ],
    lastParagraph: ANOTHER_FILE,
  },
  J007: {
    title: 'No image selected.',
    errorId: 'J007',
    // paragraphs: ['Please select an image.'],
  },
  J008: {
    title: 'Too many images.',
    errorId: 'J008',
  },
  J009: {
    title: 'Unexpected error while generating confidence bar.',
    errorId: 'J009',
    paragraphs: [TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J010: {
    title: 'Failed to read image file.',
    errorId: 'J010',
    paragraphs: [TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J011: {
    title: 'No results available.',
    errorId: 'J011',
    paragraphs: [REFRESH_PAGE_SELECT_STUDY],
    lastParagraph: SUPPORT,
  },
  J012: {
    title: 'The username and password entered do not match our records.',
    errorId: 'J012',
  },
  // J013: {
  //   title: 'This account is no longer active.',
  //   errorId: 'J013',
  //   paragraphs: [TRY_AGAIN],
  //   lastParagraph: SUPPORT,
  // },
  J014: {
    title: 'Cannot reach Annalise.ai servers.',
    errorId: 'J014',
    paragraphs: [TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J015: {
    title: 'Cannot reach Annalise.ai servers.',
    errorId: 'J015',
    paragraphs: [TRY_AGAIN],
    lastParagraph: ANOTHER_FILE,
  },
  J016: {
    title: 'PA/AP image required for AI analysis.',
    errorId: 'J016',
    lastParagraph: DIFFERENT_STUDY,
  },
  J017: {
    title: 'Chest X-ray required for AI analysis.',
    errorId: 'J017',
    lastParagraph: DIFFERENT_STUDY,
  },
  J018: {
    title: 'Unexpected error analysing study.',
    errorId: 'J018',
    paragraphs: [DIFFERENT_STUDY],
    lastParagraph: SUPPORT,
  },
  J019: {
    title: 'Unexpected error analysing study.',
    errorId: 'J019',
    paragraphs: [DIFFERENT_STUDY],
    lastParagraph: SUPPORT,
  },
  J020: {
    title: 'Unexpected error analysing study.',
    errorId: 'J020',
    paragraphs: [DIFFERENT_STUDY],
    lastParagraph: SUPPORT,
  },
  J021: {
    title: 'Unexpected error analysing study.',
    errorId: 'J021',
    paragraphs: [DIFFERENT_STUDY],
    lastParagraph: SUPPORT,
  },
  J022: {
    title: 'Unexpected error analysing study.',
    errorId: 'J022',
    paragraphs: [DIFFERENT_STUDY],
    lastParagraph: SUPPORT,
  },
  J023: {
    title: 'Unexpected network error.',
    errorId: 'J023',
    paragraphs: ['Please try uploading again'],
    lastParagraph: SUPPORT,
  },
  J024: {
    title: 'All files must be of the same file type.',
    errorId: 'J024',
    paragraphs: [
      'When uploading multiple files, all file types must be the same.',
      'Please try again by separating the uploads.',
    ],
  },
  J025: {
    title: 'Image status timeout.',
    errorId: 'J025',
    paragraphs: ['Please try uploading again.'],
    lastParagraph: SUPPORT,
  },
  J026: {
    title: 'Segment status timeout.',
    errorId: 'J026',
    paragraphs: ['Please try uploading again.'],
    lastParagraph: SUPPORT,
  },
  J027: {
    title: 'Must be 16/18 years or older for AI analysis.',
    errorId: 'J027',
    paragraphs: [
      'Annalise currently only supports studies with patients that are:',
      '16 years or older (CXR)',
      '18 years or older (CTB)',
    ],
    lastParagraph: DIFFERENT_STUDY,
  },
  J028: {
    title: 'Too many images for AI analysis.',
    errorId: 'J028',
    paragraphs: ['Annalise CXR currently only supports studies with a maximum 16 images.'],
    lastParagraph: DIFFERENT_STUDY,
  },
  J029: {
    title: 'You are no longer logged in.',
    errorId: 'J029',
    paragraphs: ['Please re-enter your credentials and try again.'],
    lastParagraph: SUPPORT,
  },
  J030: {
    title: 'Could not upload study.',
    errorId: 'J030',
    paragraphs: ['Sorry study uploads from your organisation are currently at a high volume.', TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J031: {
    title: 'Could not upload study.',
    errorId: 'J031',
    paragraphs: [`Sorry you have exceeded the ${MAX_FREE_STUDIES} studies per week limit.`],
    lastParagraph: MORE_UPLOADS_SUPPORT,
  },
  J032: {
    title: 'Unexpected error.',
    errorId: 'J032',
    paragraphs: [TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J098: {
    title: 'Unexpected error.',
    errorId: 'J098',
    lastParagraph: SUPPORT,
  },
  J099: {
    title: 'Unexpected error.',
    errorId: 'J099',
    lastParagraph: SUPPORT,
  },
  J100: {
    title: 'This feature is not yet available in your region.',
    errorId: '',
  },
  J101: {
    errorId: 'J101',
    title: 'Unable to detect a non-contrast brain series.',
  },
  J102: {
    errorId: 'J102',
    title: 'Slice thickness not supported.',
  },
  J103: {
    errorId: 'J103',
    title: 'Study not supported.',
  },
  J104: {
    errorId: 'J104',
    title: 'Study not supported.',
  },
  J105: {
    errorId: 'J105',
    title: 'Unable to detect series for processing.',
  },
  J106: {
    errorId: 'J106',
    title: 'Unable to detect series for processing.',
  },
  J107: {
    errorId: 'J107',
    title: 'Unable to analyse study.',
  },
  J108: {
    errorId: 'J108',
    title: 'Unable to analyse study.',
  },
  J109: {
    errorId: 'J109',
    title: 'Unable to analyse study.',
  },
  J110: {
    errorId: 'J110',
    title: 'Unable to analyse study.',
  },
  J111: {
    errorId: 'J111',
    title: 'Unable to analyse study.',
  },
  J112: {
    errorId: 'J112',
    title: 'Unable to analyse study.',
  },
  J113: {
    errorId: 'J113',
    title: 'Unable to analyse study.',
  },
  J114: {
    errorId: 'J114',
    title: 'Unable to analyse study.',
  },
  J115: {
    errorId: 'J115',
    title: 'Unable to analyse study.',
  },
  J116: {
    errorId: 'J116',
    title: 'Unable to analyse study.',
  },
  J117: {
    errorId: 'J117',
    title: 'Unable to analyse study.',
  },
  J118: {
    errorId: 'J118',
    title: 'Unable to analyse study.',
  },
  // Statuses
  J119: {
    errorId: 'J119',
    title: 'Study processing.',
    paragraphs: [TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J120: {
    errorId: 'J120',
    title: 'Study processing.',
    paragraphs: [TRY_AGAIN],
    lastParagraph: SUPPORT,
  },
  J121: {
    errorId: 'J121',
    title: 'Invalid app configuration.',
  },
  J122: {
    errorId: 'J122',
    title: 'Failed to initialise Keycloak.',
  },
  J123: {
    errorId: 'J123',
    title: 'Real-time updates not available.',
    paragraphs: [REFRESH_PAGE],
    lastParagraph: SUPPORT,
  },
  // Study Delete Errors
  J124: {
    title: STUDY_DELETE,
    errorId: 'J124',
    paragraphs: [STUDY_DELETE_NOT_PROCESSED],
    lastParagraph: SUPPORT_WITH_ERROR_CODE,
  },
  J125: {
    title: STUDY_DELETE,
    errorId: 'J125',
    paragraphs: [STUDY_DELETE_NOT_PROCESSED],
    lastParagraph: SUPPORT_WITH_ERROR_CODE,
  },
  J126: {
    title: STUDY_DELETE,
    errorId: 'J126',
    paragraphs: [STUDY_DELETE_NOT_PROCESSED],
    lastParagraph: SUPPORT_WITH_ERROR_CODE,
  },
  J127: {
    title: STUDY_DELETE,
    errorId: 'J127',
    paragraphs: [STUDY_DELETE_NOT_PROCESSED],
    lastParagraph: SUPPORT_WITH_ERROR_CODE,
  },
};

export default errorsMapping;

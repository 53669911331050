import styled from 'styled-components';
import colors from 'constants/colors';

const { FOCUS, ON_SECONDARY } = colors;

const SignOutButton = styled.button`
  font-size: 0.625rem;
  line-height: 1rem;
  text-decoration: underline;

  &:hover {
    color: ${ON_SECONDARY};
  }

  &:focus {
    color: ${FOCUS};
    outline: none;
  }
`;

export default SignOutButton;

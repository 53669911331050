import styled from 'styled-components';
import colors from 'constants/colors';

type FindingBadgeProps = {
  backgroundColor?: string;
  color?: string;
  opacity?: number;
  children: string | number | JSX.Element | JSX.Element[] | (() => JSX.Element);
};

const { ON_PRIMARY, NEUTRAL_BACKGROUND } = colors;
const FindingBadgeContainer = styled.div<FindingBadgeProps>`
  display: flex;
  align-items: center;
  margin: auto;
  width: auto;
  min-width: 1.3rem;
  height: 1.3rem;
  background-color: ${({ backgroundColor = NEUTRAL_BACKGROUND }) => backgroundColor};
  border-radius: 10px;
  opacity: ${({ opacity = 1 }) => opacity};
  justify-content: center;
`;

const FindingBadgeContent = styled.span<FindingBadgeProps>`
  color: ${({ color = ON_PRIMARY }) => color};
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;
  margin: 3px 8px;
`;

const FindingBadge = ({ backgroundColor, color, children, opacity }: FindingBadgeProps): JSX.Element => {
  return (
    <FindingBadgeContainer backgroundColor={backgroundColor} opacity={opacity}>
      <FindingBadgeContent color={color}>{children}</FindingBadgeContent>
    </FindingBadgeContainer>
  );
};

export default FindingBadge;

import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Disclaimer from 'components/Disclaimer/Disclaimer';
import colors from 'constants/colors';
import demoDisclaimerProps from 'constants/demoDisclaimerProps';
import { PORTRAIT } from 'constants/orientation';
import { BREAK_POINTS } from 'constants/sizes';
import useStrings from 'hooks/useStrings';

const { SAMPLE_SCREEN_LARGE_MIN_WIDTH } = BREAK_POINTS;
const { ON_PRIMARY, ON_SECONDARY } = colors;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${ON_PRIMARY};
  margin-bottom: 3rem;
`;

const Heading = styled.h2`
  font-size: 1.125rem;
  color: ${ON_SECONDARY};
  margin-bottom: 1rem;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.5rem;
  }
`;

const SubHeading = styled.h3`
  font-size: 1.5rem;
  line-height: 1.9rem;
  margin-bottom: 1.5rem;
`;

const PublicTitleArea: FC = () => {
  const { PUBLIC_HEADING, PUBLIC_SUBHEADING } = useStrings();
  const isPortrait = useMediaQuery({ orientation: PORTRAIT });
  const isLessThan768H = useMediaQuery({
    query: '(max-Height: 767px)',
  });
  const hideHeading = !isPortrait && isLessThan768H;

  return (
    <HeaderContainer>
      {!hideHeading && <Heading>{PUBLIC_HEADING}</Heading>}
      <SubHeading>{PUBLIC_SUBHEADING}</SubHeading>
      <Disclaimer {...demoDisclaimerProps} />
    </HeaderContainer>
  );
};

export default PublicTitleArea;

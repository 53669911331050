import { size } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';
import colors from 'constants/colors';

const { ON_NEUTRAL_BACKGROUND, PRIMARY_OUTLINE, SECONDARY, SECONDARY_CONTAINER, SECONDARY_OUTLINE, FOCUS } = colors;
const { SPACING_01, SPACING_02, SPACING_04, SPACING_06 } = size;

export const ViewedFilterContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  gap: ${SPACING_04}px;
  justify-content: flex-end;
  margin-right: ${SPACING_06}px;
  width: 100%;
`;

export const FilterLabel = styled.label`
  color: ${ON_NEUTRAL_BACKGROUND};
`;

export const FilterButtonsContainer = styled.div`
  align-items: flex-end;
  background-color: ${SECONDARY};
  border: ${SPACING_01}px solid ${SECONDARY_OUTLINE};
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  gap: 6px;
  justify-content: space-between;
  padding: 6px;
`;

export const FilterButton = styled.button<{ $isSelected: boolean }>`
  background-color: ${SECONDARY};
  box-shadow: 0 0 0 ${SPACING_02}px ${SECONDARY};
  border-radius: 1rem;
  box-sizing: border-box;
  padding: ${SPACING_02}px ${SPACING_04}px;
  outline: none;

  ${({ $isSelected }) =>
    $isSelected &&
    `
      background-color: ${SECONDARY_CONTAINER};
      box-shadow: 0 0 0 ${SPACING_02}px ${PRIMARY_OUTLINE};
    `}

  &:hover {
    background-color: ${SECONDARY_CONTAINER};
    outline: none;
  }

  &:active,
  &:focus {
    background-color: ${SECONDARY_CONTAINER};
    box-shadow: 0 0 0 ${SPACING_01}px ${FOCUS};
    outline: none;
  }
`;

import colors from 'constants/colors';
import cornerstone from 'cornerstone-core';
import { CornerstoneEnabledElement } from 'types/cornerstone/CornerstoneEnabledElement';
import { CornerstoneWebImage } from 'types/cornerstone/CornerstoneImage';

const { LOCALISATION_OVERLAY } = colors;

/**
 * Simplified version of the cornerstone renderWebImage function.
 * Used to make a transparent background and colourise the segment.
 * */
const renderSegment = (enabledElement: CornerstoneEnabledElement<CornerstoneWebImage>): void => {
  const { image, canvas, viewport } = enabledElement;

  const context = canvas.getContext('2d', {
    desynchronized: true,
  });

  if (!context) {
    return;
  }

  context.setTransform(1, 0, 0, 1, 0, 0);

  context.clearRect(0, 0, canvas.width, canvas.height);

  context.imageSmoothingEnabled = !viewport.pixelReplication;

  cornerstone.setToPixelCoordinateSystem(enabledElement, context);

  const width = image.columns;
  const height = image.rows;

  context.globalCompositeOperation = 'source-over';

  context.drawImage(image.getImage(), 0, 0, width, height, 0, 0, width, height);

  context.globalCompositeOperation = 'source-in';

  context.fillStyle = LOCALISATION_OVERLAY;
  context.fillRect(0, 0, canvas.width, canvas.height);
};

export default renderSegment;

import { StatusCodes } from 'http-status-codes';
import client from 'api/client';
import endpoints from 'api/endpoints';
import errorsMapping from 'constants/errorsMapping';
import DisplayError from 'types/DisplayError';

type ErrorsMappingType = { [key: number]: DisplayError };
const { BAD_REQUEST, UNAUTHORIZED, NOT_FOUND, INTERNAL_SERVER_ERROR } = StatusCodes;

export const ERRORS_MAPPING: ErrorsMappingType = {
  [BAD_REQUEST]: errorsMapping['J124'], // 400
  [UNAUTHORIZED]: errorsMapping['J125'], // 401
  [NOT_FOUND]: errorsMapping['J126'], // 404
  [INTERNAL_SERVER_ERROR]: errorsMapping['J127'], // 500
};

const deleteStudies = async (accessionNumber: string, reason?: string): Promise<void> => {
  const { adminStudies } = endpoints.v1;
  const path = adminStudies.getPath();

  const data = {
    accessionNumber,
    ...(reason && { reason }),
  };

  return await client.delete(path, { data });
};

export default deleteStudies;

import { SaveableFilters } from './SearchFilter.types';

export const SAVED_FILTERS = 'savedFilters';

const getSavedFilters = (): SaveableFilters => {
  const savedFilters = localStorage.getItem(SAVED_FILTERS);
  return (savedFilters && JSON.parse(savedFilters)) || {};
};

export default getSavedFilters;

import { FormEvent, ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { UserInput } from 'components/Form/Form.types';
import { isCalendarOpenAction, isEnterAction, isTabAction } from 'components/Form/Form.utils';
import maybeShowColumn from 'components/WorklistTable/utils/maybeShowColumn';
import { SCAN_COL } from 'components/WorklistTable/WorklistTable.constants';
import colors from 'constants/colors';
import INPUTS from 'constants/inputs';
import DateFormats from 'enums/DateFormats';
import Hotkeys from 'enums/Hotkeys';
import IconDirection from 'enums/IconDirection';
import formatDateTime from 'helpers/dates/formatDateTime';
import Calendar from 'icons/Calendar';
import Chevron from 'icons/Chevron';
import Dismiss from 'icons/Dismiss';
import Save from 'icons/Save';
import SaveSolid from 'icons/SaveSolid';
import Search from 'icons/Search';
import { selectDisplayData, selectInstitutionNames, selectRequestingServices } from 'selectors/settings/selectSettings';
import {
  BUTTON_ELEMENTS,
  DATE_TYPES_OPTIONS,
  INPUT_ELEMENTS,
  MINIMUM_DATE_SELECTION,
  SEARCH_FORM_ATTRS,
  STUDY_TYPES_OPTIONS,
} from './SearchFilter.constants';
import {
  CalendarIconContainer,
  ChevronIconContainer,
  ClearSearchButton,
  DateInputContainer,
  DateTypeContainer,
  GhostButton,
  InlineWrapper,
  InstitutionNameContainer,
  PrimaryButton,
  RequestingServicesContainer,
  SearchFilterForm,
  SearchIconContainer,
  SearchInput,
  SearchInputContainer,
  SecondaryButton,
  Separator,
  StudyTypeContainer,
  StyledDateInput,
  StyledDateInputHidden,
  StyledSelect,
  WrapContainer,
} from './SearchFilter.styles';
import { SelectOptions } from './SearchFilter.types';
import { buildOrgFilterOptions, FilterNames, getTodaysDate, maybeShowFilter } from './SearchFilter.utils';
import useSearchFilter from './useSearchFilter';

const { RequestingServices, InstitutionNames } = FilterNames;
const { SEARCH_HOTKEY } = Hotkeys;
const { DOWN } = IconDirection;
const { ON_NEUTRAL_BACKGROUND, ON_SECONDARY } = colors;
const { FORMAT_DD_MMM_YYYY } = DateFormats;

const {
  CREATED_AFTER_ATTRS,
  CREATED_AFTER_INPUT_ATTRS,
  CREATED_UNTIL_ATTRS,
  CREATED_UNTIL_INPUT_ATTRS,
  DATE_TYPE_ATTRS,
  INSTITUTION_NAME_ATTRS,
  REQUESTING_SERVICES_ATTRS,
  SEARCH_ATTRS,
  STUDY_TYPE_ATTRS,
} = INPUT_ELEMENTS;
const { SEARCH_BUTTON_ATTRS, RESET_BUTTON_ATTRS, CLEAR_SEARCH_ATTRS, FILTERS_SAVED_ATTRS, SAVE_FILTERS_ATTRS } =
  BUTTON_ELEMENTS;
const {
  INSTITUTION_NAME_AVAILABLE_TITLE,
  REQUESTING_SERVICES_AVAILABLE_TITLE,
  SEARCH_BUTTON_AVAILABLE_TITLE,
  SEARCH_BUTTON_UNAVAILABLE_TITLE,
} = INPUTS;

const renderOptions = (options: SelectOptions[]) =>
  options.map(({ name, value }, idx) => (
    <option key={`${idx}-${value}`} value={value}>
      {name}
    </option>
  ));

const SearchFilter = (): ReactElement => {
  const {
    canSearchForm,
    canResetForm,
    canSaveFilters,
    filtersHaveBeenSaved,
    handleChange,
    handleClearSearch,
    handleReset,
    handleSearch,
    handleSaveFilters,
    isCustomDates,
    values: { search, institutionName, requestingService, studyType, dateType, createdAfter, createdUntil },
  } = useSearchFilter();
  const searchRef = useRef<HTMLInputElement>(null);
  const createdAfterRef = useRef<HTMLInputElement>(null);
  const createdUntilRef = useRef<HTMLInputElement>(null);
  const showStudyType = maybeShowColumn(SCAN_COL);

  // Current org's requesting services and hospitals, pulled from the backend
  const requestingServices = useSelector(selectRequestingServices);
  const institutionNames = useSelector(selectInstitutionNames);

  // Current org's display settings
  const displayData = useSelector(selectDisplayData);
  const inputDateFormat = displayData?.dateFormats?.displayFormat || 'DD/MM/YYYY';

  const [isHovered, setIsHovered] = useState(false);

  const [institutionNameOptions, setInstitutionNameOptions] = useState<SelectOptions[]>([]);
  const [requestingServicesOptions, setRequestingServicesOptions] = useState<SelectOptions[]>([]);

  const hasSearchTerm = (search || '').length > 0;
  const todaysDate = getTodaysDate();

  // Date input handlers
  const handleOpenCalendar = (ref: RefObject<HTMLInputElement>) => (): void => ref?.current?.showPicker?.();
  const handleDateInputKeyDown =
    (ref: RefObject<HTMLInputElement>) =>
    (e: UserInput): void => {
      // Unless tabbing to next/previous input, prevent default
      // NB: suppresses the default enter/submit action, as handled above via handleSearch()
      !isTabAction(e) && e.preventDefault();
      // Open calendar if valid keys pressed
      isCalendarOpenAction(e) && handleOpenCalendar(ref)();
      // Perform search on enter press
      isEnterAction(e) && handleSearch(e);
    };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  // Ensure search bar is focused on render and when search is cleared
  useEffect(() => {
    searchRef?.current?.focus();
  }, []);

  useEffect(() => {
    setRequestingServicesOptions(buildOrgFilterOptions(RequestingServices, requestingServices, requestingService));
    setInstitutionNameOptions(buildOrgFilterOptions(InstitutionNames, institutionNames, institutionName));
  }, [institutionName, institutionNames, requestingService, requestingServices]);

  useEffect(() => {
    search === '' && searchRef?.current?.focus();
  }, [search]);

  // Use hotkey to jump focus to search bar
  useHotkeys(SEARCH_HOTKEY, () => searchRef?.current?.focus(), [searchRef]);

  return (
    <SearchFilterForm {...SEARCH_FORM_ATTRS} onSubmit={handleSubmit}>
      <SearchInputContainer>
        <SearchInput
          {...SEARCH_ATTRS}
          ref={searchRef}
          autoComplete="off"
          value={search}
          onChange={handleChange}
          onKeyDown={handleSearch}
        />
        <SearchIconContainer>
          <Search />
        </SearchIconContainer>
        <ClearSearchButton {...CLEAR_SEARCH_ATTRS} $hasSearchTerm={hasSearchTerm} onClick={handleClearSearch}>
          <Dismiss />
        </ClearSearchButton>
      </SearchInputContainer>
      <Separator />
      {maybeShowFilter(institutionNameOptions) && (
        <InstitutionNameContainer>
          <StyledSelect
            {...INSTITUTION_NAME_ATTRS}
            value={institutionName}
            onChange={handleChange}
            onKeyDown={handleSearch}
            title={INSTITUTION_NAME_AVAILABLE_TITLE}
          >
            {renderOptions(institutionNameOptions)}
          </StyledSelect>
          <ChevronIconContainer>
            <Chevron active direction={DOWN} />
          </ChevronIconContainer>
        </InstitutionNameContainer>
      )}
      {maybeShowFilter(requestingServicesOptions) && (
        <RequestingServicesContainer>
          <StyledSelect
            {...REQUESTING_SERVICES_ATTRS}
            value={requestingService}
            onChange={handleChange}
            onKeyDown={handleSearch}
            title={REQUESTING_SERVICES_AVAILABLE_TITLE}
          >
            {renderOptions(requestingServicesOptions)}
          </StyledSelect>
          <ChevronIconContainer>
            <Chevron active direction={DOWN} />
          </ChevronIconContainer>
        </RequestingServicesContainer>
      )}
      {(maybeShowFilter(institutionNameOptions) || maybeShowFilter(requestingServicesOptions)) && <Separator />}
      {showStudyType && (
        <>
          <StudyTypeContainer>
            <StyledSelect {...STUDY_TYPE_ATTRS} value={studyType} onChange={handleChange} onKeyDown={handleSearch}>
              {renderOptions(STUDY_TYPES_OPTIONS)}
            </StyledSelect>
            <ChevronIconContainer>
              <Chevron active direction={DOWN} />
            </ChevronIconContainer>
          </StudyTypeContainer>
          <Separator />
        </>
      )}
      <DateTypeContainer>
        <StyledSelect {...DATE_TYPE_ATTRS} value={dateType} onChange={handleChange} onKeyDown={handleSearch}>
          {renderOptions(DATE_TYPES_OPTIONS)}
        </StyledSelect>
        <ChevronIconContainer>
          <Chevron active direction={DOWN} />
        </ChevronIconContainer>
      </DateTypeContainer>
      <WrapContainer>
        {isCustomDates && (
          <>
            <DateInputContainer onClick={handleOpenCalendar(createdAfterRef)}>
              <StyledDateInput
                {...CREATED_AFTER_INPUT_ATTRS}
                placeholder={inputDateFormat}
                value={formatDateTime(createdAfter || '', FORMAT_DD_MMM_YYYY(inputDateFormat), createdAfter)}
                onKeyDown={handleDateInputKeyDown(createdAfterRef)}
                readOnly={true}
              />
              <CalendarIconContainer>
                <Calendar />
              </CalendarIconContainer>
              <StyledDateInputHidden
                {...CREATED_AFTER_ATTRS}
                ref={createdAfterRef}
                value={createdAfter}
                min={MINIMUM_DATE_SELECTION}
                max={createdUntil || todaysDate}
                onChange={handleChange}
                tabIndex={-1}
              />
            </DateInputContainer>
            <DateInputContainer onClick={handleOpenCalendar(createdUntilRef)}>
              <StyledDateInput
                {...CREATED_UNTIL_INPUT_ATTRS}
                placeholder={inputDateFormat}
                value={formatDateTime(createdUntil || '', FORMAT_DD_MMM_YYYY(inputDateFormat), createdUntil)}
                onKeyDown={handleDateInputKeyDown(createdUntilRef)}
                readOnly={true}
              />
              <CalendarIconContainer>
                <Calendar />
              </CalendarIconContainer>
              <StyledDateInputHidden
                {...CREATED_UNTIL_ATTRS}
                ref={createdUntilRef}
                value={createdUntil}
                min={createdAfter || MINIMUM_DATE_SELECTION}
                max={todaysDate}
                onChange={handleChange}
                tabIndex={-1}
              />
            </DateInputContainer>
          </>
        )}
        <Separator />
        <PrimaryButton
          {...SEARCH_BUTTON_ATTRS}
          onClick={handleSearch}
          disabled={!canSearchForm}
          title={canSearchForm ? SEARCH_BUTTON_AVAILABLE_TITLE : SEARCH_BUTTON_UNAVAILABLE_TITLE}
        >
          {SEARCH_BUTTON_ATTRS['aria-label']}
        </PrimaryButton>
        {canResetForm && (
          <SecondaryButton {...RESET_BUTTON_ATTRS} onClick={handleReset}>
            {RESET_BUTTON_ATTRS['aria-label']}
          </SecondaryButton>
        )}
        {canSaveFilters && !filtersHaveBeenSaved && (
          <GhostButton
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...SAVE_FILTERS_ATTRS}
            onClick={handleSaveFilters}
          >
            <InlineWrapper>
              {(isHovered && <SaveSolid color={ON_SECONDARY} />) || <Save color={ON_NEUTRAL_BACKGROUND} />}
              {SAVE_FILTERS_ATTRS['aria-label']}
            </InlineWrapper>
          </GhostButton>
        )}
        {filtersHaveBeenSaved && !canSaveFilters && (
          <GhostButton disabled {...FILTERS_SAVED_ATTRS}>
            {FILTERS_SAVED_ATTRS['aria-label']}
          </GhostButton>
        )}
      </WrapContainer>
    </SearchFilterForm>
  );
};

export default SearchFilter;

// A list of allowable search query params, used to retain only valid params before sending request to the backend
const SEARCH_QUERY_PARAMS = [
  'createdAfter',
  'createdUntil',
  'direction',
  'limit',
  'page',
  'institutionName',
  'requestingService',
  'search',
  'sortBy',
  'studyType',
  'viewed',
];

export default SEARCH_QUERY_PARAMS;

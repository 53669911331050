import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { ICON_WINDOW_SETTINGS, ICON_WINDOW_SETTINGS_SOLID } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

const WindowSettings: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY }) => (
  <Container size={size} $testId={ICON_WINDOW_SETTINGS}>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M6 3C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H9.59971C9.43777 16.6832 9.30564 16.3486 9.20703 16H6C4.89543 16 4 15.1046 4 14V7H16V9.20703C16.3486 9.30564 16.6832 9.43777 17 9.59971V6C17 4.34315 15.6569 3 14 3H6ZM4 6C4 4.89543 4.89543 4 6 4H14C15.1046 4 16 4.89543 16 6L4 6ZM12.0647 11.442C12.368 12.5231 11.722 13.642 10.6341 13.9199L10.173 14.0377C10.1581 14.1896 10.1504 14.3438 10.1504 14.4999C10.1504 14.6875 10.1615 14.8725 10.1831 15.0541L10.5326 15.1368C11.652 15.4014 12.318 16.5536 11.9887 17.6557L11.8623 18.0785C12.1195 18.2817 12.3993 18.4559 12.697 18.5964L13.022 18.2523C13.8118 17.416 15.1426 17.4167 15.9315 18.2538L16.2689 18.6118C16.5613 18.4769 16.8367 18.3095 17.0907 18.1142L16.9346 17.5575C16.6313 16.4764 17.2773 15.3575 18.3652 15.0795L18.8258 14.9619C18.8408 14.81 18.8484 14.6559 18.8484 14.4999C18.8484 14.3121 18.8373 14.127 18.8157 13.9452L18.4667 13.8627C17.3473 13.598 16.6813 12.4459 17.0106 11.3438L17.1368 10.9215C16.8796 10.7182 16.5998 10.5439 16.302 10.4034L15.9773 10.7472C15.1875 11.5835 13.8568 11.5828 13.0678 10.7457L12.7305 10.3877C12.438 10.5226 12.1626 10.6899 11.9086 10.8852L12.0647 11.442ZM14.4994 15.4999C13.9471 15.4999 13.4994 15.0521 13.4994 14.4999C13.4994 13.9476 13.9471 13.4999 14.4994 13.4999C15.0517 13.4999 15.4994 13.9476 15.4994 14.4999C15.4994 15.0521 15.0517 15.4999 14.4994 15.4999Z"
        fill={color}
        fillOpacity=".75"
      />
    </svg>
  </Container>
);

export default WindowSettings;

export const WindowSettingsSolid: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY }) => (
  <Container size={size} $testId={ICON_WINDOW_SETTINGS_SOLID}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
      <path
        fill={color}
        d="M3 6a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v3.6a5.465 5.465 0 0 0-1-.393V7H4v7a2 2 0 0 0 2 2h3.207c.099.349.23.683.393 1H6a3 3 0 0 1-3-3V6Zm9.065 5.442a2 2 0 0 1-1.43 2.478l-.462.118a4.734 4.734 0 0 0 .01 1.016l.35.083a2 2 0 0 1 1.456 2.519l-.127.422c.258.204.537.378.835.518l.325-.344a2 2 0 0 1 2.91.002l.337.358c.292-.135.568-.302.822-.498l-.156-.556a2 2 0 0 1 1.43-2.479l.46-.117a4.7 4.7 0 0 0-.01-1.017l-.348-.082a2 2 0 0 1-1.456-2.52l.126-.421a4.318 4.318 0 0 0-.835-.519l-.325.344a2 2 0 0 1-2.91-.001l-.337-.358a4.31 4.31 0 0 0-.821.497l.156.557Zm2.434 4.058a1 1 0 1 1 0-2a1 1 0 0 1 0 2Z"
      />
    </svg>
  </Container>
);

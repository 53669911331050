import { ReactElement } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Container from 'icons/Container';
import Icon from 'types/Icon';

const { ON_PRIMARY } = colors;
const { ICON_ISO_MD } = ReactTestingLibraryDataProperties;

const IsoMd = ({ color = ON_PRIMARY, size = 1.5 }: Icon): ReactElement => (
  <Container size={size} $testId={ICON_ISO_MD}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.15461 15.9389V8.20151H6.49272L7.89665 13.4794L9.28474 8.20151H11.6281V15.9389H10.1767V9.84821L8.64083 15.9389H7.13663L5.60604 9.84821V15.9389H4.15461Z"
        fill={color}
      />
      <path
        d="M13.3907 8.20151H16.2461C16.89 8.20151 17.3808 8.25077 17.7186 8.34929C18.1725 8.48299 18.5613 8.7205 18.885 9.06181C19.2088 9.40311 19.4551 9.82182 19.624 10.3179C19.7928 10.8106 19.8773 11.4193 19.8773 12.1441C19.8773 12.781 19.7981 13.3299 19.6398 13.7908C19.4463 14.3538 19.1701 14.8094 18.8112 15.1578C18.5402 15.4217 18.1743 15.6275 17.7134 15.7753C17.3685 15.8844 16.9076 15.9389 16.3305 15.9389H13.3907V8.20151ZM14.953 9.51043V14.6353H16.1194C16.5557 14.6353 16.8706 14.6106 17.0642 14.5614C17.3175 14.4981 17.5269 14.3907 17.6922 14.2394C17.8611 14.0881 17.9984 13.8401 18.1039 13.4952C18.2095 13.1469 18.2623 12.6737 18.2623 12.0755C18.2623 11.4773 18.2095 11.0182 18.1039 10.698C17.9984 10.3778 17.8506 10.1279 17.6606 9.94849C17.4706 9.76904 17.2295 9.64765 16.9375 9.58432C16.7193 9.53506 16.2918 9.51043 15.655 9.51043H14.953Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1679 5.40457H1.83206V18.687H22.1679V5.40457ZM21.7099 5.86259H2.29008V18.229H21.7099V5.86259Z"
        fill={color}
      />
    </svg>
  </Container>
);

export default IsoMd;

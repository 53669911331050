import client from 'api/client';
import endpoints from 'api/endpoints';
import { APP_LOCALE } from 'slices/settingsSlice';
import { DocsManifest, DocsUrls } from 'types/DocsManifest';
import getOrgSettings from './getOrgSettings';
import getVersions from './getVersions';

const formatDocumentUrl = (documentsBucketUrl: string, documentUrl: string): string =>
  documentUrl?.startsWith('/') ? `${documentsBucketUrl}${documentUrl}` : documentUrl;

const getDocsManifest = async (applicationVersion: string | undefined): Promise<DocsUrls> => {
  const { documents } = endpoints.v1;
  const path = documents.getPath();
  const {
    data: { documentsBucketUrl },
  } = await client.get(path);

  const MANIFEST_URL = '/webviewer/manifest.json';
  const { backend, legal, privacy, webviewer }: DocsManifest = await fetch(`${documentsBucketUrl}${MANIFEST_URL}`, {
    cache: 'no-store',
  }).then(res => res.json());

  const { region } = await getOrgSettings();
  const { annaliseBackendVersion } = await getVersions();

  const userGuideVersion = applicationVersion?.split('-')[0];

  const userGuideDocs = userGuideVersion && webviewer[userGuideVersion];
  const { [annaliseBackendVersion]: performanceSpecificationsDocs } = backend;

  const documentUrls: DocsUrls = {
    userGuide:
      region && userGuideDocs && formatDocumentUrl(documentsBucketUrl, userGuideDocs[region]?.userGuide[APP_LOCALE]),
    performanceSpecifications:
      region &&
      performanceSpecificationsDocs &&
      formatDocumentUrl(
        documentsBucketUrl,
        performanceSpecificationsDocs[region]?.performanceSpecifications[APP_LOCALE],
      ),
    privacyPolicy: privacy.en && formatDocumentUrl(documentsBucketUrl, privacy.en),
    legalNotices: privacy.en && formatDocumentUrl(documentsBucketUrl, legal.en),
  };

  return documentUrls;
};

export default getDocsManifest;

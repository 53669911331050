import styled from 'styled-components';
import colors from 'constants/colors';

const { DROP_SHADOW, NEUTRAL_BACKGROUND_INVERT, ON_PRIMARY } = colors;

const Popup = styled.div`
  padding: 2.2rem 2.2rem 2rem;
  position: absolute;
  width: 27.5rem;
  height: 16.25rem;
  left: 5.15rem;
  bottom: 5.15rem;
  background: ${NEUTRAL_BACKGROUND_INVERT};
  box-shadow: 0 0 1rem ${DROP_SHADOW};
  color: ${ON_PRIMARY};
`;

export default Popup;

import styled from 'styled-components';
import colors from 'constants/colors';

const { ON_SECONDARY } = colors;
export const NoPermissionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NoPermissionsHeader = styled.h1`
  color: ${ON_SECONDARY};
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  width: 23.875rem;
`;
export const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 4.5rem 0;
  grid-template-areas:
    'nav'
    'main';
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: main;
`;

import 'normalize.css';
import 'tailwind-preflight.css';
import 'index.css';
// Initialize cornerstone in the app root to be sure that all cornerstone libraries are set up before importing
import 'helpers/cornerstone/initializeCornerstone';
import { AuthScope } from '@annaliseai/api-specifications';
import { ConnectedRouter } from 'connected-react-router';
import log from 'loglevel';
import { StrictMode, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled, { StyleSheetManager } from 'styled-components';
import history from 'browserHistory';
import CookiesToggle from 'components/CookiesToggle';
import LoadingIndicator from 'components/LoadingIndicator';
import ErrorModal from 'components/Modals/ErrorModal';
import SmallScreenBlockingModal from 'components/Modals/SmallScreenBlockingModal';
import UserInformationCheck from 'components/UserInformationCheck';
import configuration from 'configuration';
import errorsMapping from 'constants/errorsMapping';
import CustomErrors from 'enums/CustomErrors';
import Routes from 'enums/Routes';
import getMatomoEnv from 'helpers/analytics/getMatomoEnvironment';
import useNavigationTracker from 'helpers/analytics/useNavigationTracker';
import { isLoggedIn } from 'helpers/limitationHelper';
import shouldForwardProp from 'helpers/shouldForwardProp';
import useStrings from 'hooks/useStrings';
import keycloak from 'keycloak';
import PublicLayout from 'layouts/PublicLayout';
import { createInstance, MatomoProvider, useMatomo } from 'matomo/react';
import PrivateRoute from 'routes/PrivateRoute';
import PrivateRouteKeycloak from 'routes/PrivateRouteKeyCloak';
import AdminPortal from 'screens/AdminPortal/AdminPortal';
import CtbSampleScreen from 'screens/CtbSampleScreen';
import CxrSampleScreen from 'screens/CxrSampleScreen';
import DemoScreen from 'screens/DemoScreen';
import ListScreen from 'screens/ListScreen';
import LoginScreen from 'screens/LoginScreen/LoginScreen';
import ProgressScreen from 'screens/ProgressScreen';
import StageScreen from 'screens/StageScreen';
import UserInformationScreen from 'screens/UserInformationScreen';
import ViewerScreen from 'screens/ViewerScreen/ViewerScreen';
import Worklist from 'screens/Worklist/Worklist';
import { errorActions } from 'slices/errorSlice';
import store from 'store';

const { HOME, ADMIN, LOGIN, PROGRESS, USER_INFORMATION, VIEWER, LIST, STAGE, CXR_SAMPLE, CTB_SAMPLE } = Routes;
const { READ: READ_SCOPE, ADMIN: ADMIN_SCOPE } = AuthScope;

log.setDefaultLevel('warn');

const AppContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;

  section {
    width: 100%;
    color: white;
    background-color: black;
  }
`;

const { isDemo, isViewer } = configuration;

const { setError } = errorActions;
const { FAILED_TO_INITIALISE_KEYCLOAK } = CustomErrors;

const App = () => {
  const { enableLinkTracking } = useMatomo();
  const { TITLE, TITLE_LOGGED_IN } = useStrings();
  const isUserLoggedIn = isLoggedIn();
  const [keyCloakInit, setKeycloakInit] = useState(false);
  enableLinkTracking();
  useNavigationTracker();
  const dispatch = useDispatch();

  useEffect(() => {
    if (TITLE && TITLE_LOGGED_IN) {
      const title = isUserLoggedIn ? TITLE_LOGGED_IN : TITLE;
      document.title = title;
      document.querySelector('meta[name="description"]')?.setAttribute('content', title);
    }
  }, [isUserLoggedIn, TITLE, TITLE_LOGGED_IN]);

  useEffect(() => {
    try {
      isViewer &&
        !keyCloakInit &&
        (async () => {
          await keycloak.init();
          setKeycloakInit(true);
        })();
    } catch {
      dispatch(setError(errorsMapping[FAILED_TO_INITIALISE_KEYCLOAK]));
    }
  }, [dispatch, keyCloakInit]);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <AppContainer>
        {/* PUBLIC DEMO ROUTES */}
        {isDemo && (
          <Switch>
            <Route path={LOGIN}>
              <LoginScreen />
            </Route>
            <Route exact path={HOME}>
              <PublicLayout>
                <DemoScreen />
              </PublicLayout>
            </Route>
            <Route exact path={CXR_SAMPLE}>
              <PublicLayout>
                <CxrSampleScreen />
              </PublicLayout>
            </Route>
            <Route exact path={CTB_SAMPLE}>
              <PublicLayout>
                <CtbSampleScreen />
              </PublicLayout>
            </Route>
            <Route path={VIEWER}>
              <UserInformationCheck>
                <ViewerScreen />
              </UserInformationCheck>
            </Route>
            <Route path={PROGRESS}>
              <PublicLayout>
                <ProgressScreen />
              </PublicLayout>
            </Route>
            <Route path={USER_INFORMATION}>
              <PublicLayout>
                <UserInformationScreen />
              </PublicLayout>
            </Route>

            {/*PRIVATE DEMO ROUTES*/}
            <PrivateRoute path={LIST}>
              <ListScreen />
            </PrivateRoute>
            <PrivateRoute path={STAGE}>
              <StageScreen />
            </PrivateRoute>
          </Switch>
        )}

        {/*VIEWER ROUTES - ALL PRIVATE*/}
        {isViewer &&
          ((keyCloakInit && (
            <Switch>
              <PrivateRouteKeycloak exact path={HOME} requiredScopes={[READ_SCOPE]}>
                <Worklist />
              </PrivateRouteKeycloak>
              <PrivateRouteKeycloak path={VIEWER} requiredScopes={[READ_SCOPE]}>
                <ViewerScreen />
              </PrivateRouteKeycloak>
              <PrivateRouteKeycloak path={ADMIN} requiredScopes={[ADMIN_SCOPE]}>
                <AdminPortal />
              </PrivateRouteKeycloak>
            </Switch>
          )) || <LoadingIndicator />)}

        <ErrorModal />
        <SmallScreenBlockingModal />
        {isDemo && <CookiesToggle />}
      </AppContainer>
    </StyleSheetManager>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <MatomoProvider value={createInstance(getMatomoEnv())}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </MatomoProvider>
    </Provider>
  </StrictMode>,
);

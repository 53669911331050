import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvents, UserInput } from 'components/Form/Form.types';
import { isSubmitAction } from 'components/Form/Form.utils';
import INPUTS from 'constants/inputs';
import CookieKeys from 'enums/CookieKeys';
import Pages from 'enums/Pages';
import { getUserSettings, storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import {
  selectCanResetForm,
  selectCanSaveFilters,
  selectCanSearchForm,
  selectStudiesQuery,
} from 'selectors/studyList/selectStudyList';
import { StudyListState, studyListActions } from 'slices/studyListSlice';
import getSavedFilters, { SAVED_FILTERS } from './getSavedFilters';
import { INPUT_ELEMENTS } from './SearchFilter.constants';
import { SaveableFilters, UseSearchFilterTypes } from './SearchFilter.types';
import { buildPredefinedDates, sanitizeSearchQueryParams, shouldEnableResetButton } from './SearchFilter.utils';

const { PAGE_1 } = Pages;
const { RESULTS_PER_PAGE_SELECTION, SEARCH_SELECTION } = CookieKeys;

const { CUSTOM_DATES } = INPUTS;
const { DATE_TYPE_ATTRS, SEARCH_ATTRS } = INPUT_ELEMENTS;

const { fetch, setQuery, resetQuery, setCanSearchForm, setCanResetForm, setCanSaveFilters } = studyListActions;

const useSearchFilter = (): UseSearchFilterTypes => {
  const dispatch = useDispatch();
  const canSearchForm = useSelector(selectCanSearchForm) || false;
  const canResetForm = useSelector(selectCanResetForm) || false;
  const canSaveFilters = useSelector(selectCanSaveFilters) || false;
  const studiesQuery = useSelector(selectStudiesQuery);

  // We need to keep internal state here so that the form can be updated without the query params being set
  // E.g. when the limit (num studies per page) changes, we don't want the other query params to be referenced (until a search is explicitly executed)
  const [values, setValues] = useState({
    createdAfter: '',
    createdUntil: '',
    dateType: '',
    institutionName: '',
    requestingService: '',
    search: '',
    studyType: '',
  });
  const [savedFilters, setSavedFilters] = useState<SaveableFilters>({
    createdAfter: '',
    createdUntil: '',
    dateType: '',
    institutionName: '',
    studyType: '',
    requestingService: '',
  });

  const [isCustomDates, setIsCustomDates] = useState(false);
  const [internalFiltersHaveBeenSaved, setInternalFiltersHaveBeenSaved] = useState(false);
  const [filtersHaveBeenSaved, setFiltersHaveBeenSaved] = useState(false);
  const [viewedStatus, setViewedStatus] = useState<boolean | undefined>();
  const [currentPage, setPage] = useState<number | undefined>();
  const [currentLimit, setLimit] = useState<number | undefined>();

  useEffect(() => {
    const localStorageFilters = getSavedFilters();
    // If there are no saved filters in localStorage
    let initialQuery: StudyListState['query'] = {
      createdAfter: '',
      createdUntil: '',
      dateType: '',
      institutionName: '',
      limit: undefined,
      page: PAGE_1,
      requestingService: '',
      studyType: '',
      ...studiesQuery,
    };
    if (Object.values(localStorageFilters).every(value => value === undefined)) {
      localStorage.setItem(
        SAVED_FILTERS,
        JSON.stringify({
          createdAfter: '',
          createdUntil: '',
          dateType: '',
          institutionName: '',
          studyType: '',
          requestingService: '',
        }),
      );
      // No need to setSavedFilters here as those are the default values for the savedFilter
    } else {
      setSavedFilters(localStorageFilters);
      initialQuery = { ...initialQuery, ...localStorageFilters };
    }

    //this is used to set the limit during the search for a query
    const resultsPerPage = getUserSettings(RESULTS_PER_PAGE_SELECTION);
    if (resultsPerPage?.limit) initialQuery.limit = resultsPerPage?.limit as number;

    // this is used to set the default search selection
    const searchSelection = getUserSettings(SEARCH_SELECTION);
    initialQuery = { ...initialQuery, ...searchSelection };

    setLimit(initialQuery.limit);
    setPage(initialQuery.page);
    setViewedStatus(initialQuery.viewed);

    dispatch(setQuery(initialQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If any of the external (page, limit, viewed) search parameters are changed,
    // the results of a search running while updating them should be on the query
    // saved in the state and not on the current filters, as filters could be changed
    // while the search button click has not happened
    if (
      currentPage !== studiesQuery.page ||
      currentLimit !== studiesQuery.limit ||
      viewedStatus !== studiesQuery.viewed
    ) {
      setPage(studiesQuery.page);
      setLimit(studiesQuery.limit);
      setViewedStatus(studiesQuery.viewed);
      const sanitizedQueryParams = sanitizeSearchQueryParams(studiesQuery);
      dispatch(fetch(sanitizedQueryParams));
    }
  }, [currentPage, currentLimit, dispatch, internalFiltersHaveBeenSaved, studiesQuery, values, viewedStatus]);

  useEffect(() => {
    // Do nothing if the filter has just been saved
    if (internalFiltersHaveBeenSaved) {
      return;
    }

    const {
      createdAfter = '',
      createdUntil = '',
      dateType = '',
      direction = '',
      institutionName = '',
      limit = '',
      page = '',
      requestingService = '',
      search = '',
      sortBy = '',
      studyType = '',
      viewed = '',
    } = studiesQuery;

    const queryToUse = {
      createdAfter,
      createdUntil,
      dateType,
      direction,
      institutionName,
      limit,
      page,
      requestingService,
      search,
      sortBy,
      studyType,
      viewed,
    };

    const sanitizedQueryParams = sanitizeSearchQueryParams(studiesQuery);
    const isResetButtonEnabled = shouldEnableResetButton(savedFilters, {
      createdAfter,
      createdUntil,
      dateType,
      institutionName,
      requestingService,
      studyType,
    });

    dispatch(fetch(sanitizedQueryParams));

    setValues({ createdAfter, createdUntil, dateType, institutionName, requestingService, search, studyType });

    dispatch(setCanSearchForm(false));
    dispatch(setCanResetForm(isResetButtonEnabled));
    dispatch(setCanSaveFilters(isResetButtonEnabled));
    setIsCustomDates(queryToUse.dateType === CUSTOM_DATES);
  }, [dispatch, internalFiltersHaveBeenSaved, savedFilters, studiesQuery]);

  // Perform a search by updating the query params, based on the internal state values
  const handleSearch = (e: UserInput) => {
    const userInputIsSubmitAction = isSubmitAction(e);
    userInputIsSubmitAction && e.preventDefault(); // Always prevent default when action is submit
    // If is submit action, is dirty, and the criteria hasn't already been searched, then perform another search
    if (userInputIsSubmitAction && canSearchForm) {
      const { limit: _l, page: _p, viewed: _v, ...studiesQueryToSave } = studiesQuery;
      dispatch(setQuery({ ...values, page: PAGE_1 }));
      storeUserSettings(SEARCH_SELECTION, { ...studiesQueryToSave, ...values, page: PAGE_1 });
      setInternalFiltersHaveBeenSaved(false);
    }
  };

  // Update the internal state values
  const handleChange = (e: ChangeEvents) => {
    const { name, value } = e?.target;

    const isSearchChange = name === SEARCH_ATTRS.name;
    const isDateTypeChange = name === DATE_TYPE_ATTRS.name;

    // Build form input values
    const newValues = {
      ...values,
      ...((isDateTypeChange &&
        (value !== CUSTOM_DATES
          ? {
              ...buildPredefinedDates(value),
            }
          : {
              createdAfter: '',
              createdUntil: '',
            })) ||
        {}),
      [name]: value,
    };

    // Only need to handle this when the date type is updated
    isDateTypeChange && setIsCustomDates(isDateTypeChange && value === CUSTOM_DATES);

    // Can save filters if it's NOT a string search, OR if it is a date change, it's NOT custom dates
    !isSearchChange && setFiltersHaveBeenSaved(false);

    dispatch(setCanSearchForm(true));
    dispatch(setCanSaveFilters(shouldEnableResetButton(savedFilters, newValues)));
    dispatch(setCanResetForm(shouldEnableResetButton(savedFilters, newValues)));
    setValues(newValues);
  };

  // Reset the values, including the default createdAfter/createdUntil values
  const handleReset = () => {
    const valuesToSet = {
      // if there is a dateType in the saved filters, we need to reconstruct the predefined dates with those values to keep the LAST_X_HOURS relative to right now.
      ...buildPredefinedDates(savedFilters.dateType),
      search: '',
      // overwrite any of the above defaults using the saved filters
      ...savedFilters,
    };
    setValues(valuesToSet);
    const { limit: _l, page: _p, viewed: _v, ...studiesQueryToSave } = studiesQuery;

    storeUserSettings(SEARCH_SELECTION, { ...studiesQueryToSave, ...valuesToSet, page: PAGE_1, viewed: undefined });
    setInternalFiltersHaveBeenSaved(false);
    dispatch(resetQuery());
  };

  const handleClearSearch = () => {
    dispatch(setCanSearchForm(true));
    setValues({
      ...values,
      search: '',
    });
  };

  const handleSaveFilters = () => {
    const { dateType, institutionName, requestingService, studyType, createdAfter, createdUntil } = values;

    const filtersToSave = {
      createdAfter,
      createdUntil,
      dateType,
      institutionName,
      requestingService,
      studyType,
    };

    try {
      localStorage.setItem(SAVED_FILTERS, JSON.stringify(filtersToSave));
      setSavedFilters(filtersToSave);
      dispatch(setCanResetForm(false));
      dispatch(setCanSaveFilters(false));
      setFiltersHaveBeenSaved(true);
      setInternalFiltersHaveBeenSaved(true);
    } catch {
      // nothing bad will happen, but we probably don't want to display a success message
      // maybe error state here one day
    }
  };

  return {
    canSearchForm,
    canResetForm,
    canSaveFilters,
    filtersHaveBeenSaved,
    handleChange,
    handleClearSearch,
    handleReset,
    handleSearch,
    handleSaveFilters,
    isCustomDates,
    values,
  };
};

export default useSearchFilter;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CookieKeys from 'enums/CookieKeys';
import Pages from 'enums/Pages';
import { storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import { selectStudiesTotalRecords, selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';

const { PAGE_1 } = Pages;
const { SEARCH_SELECTION } = CookieKeys;

// Used for the viewer worklist pagination
export type UsePaginationTypes = {
  gotoPage: (pageIndex: number) => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  totalPages: number;
  currentPage: number;
};

const usePagination = (): UsePaginationTypes => {
  const dispatch = useDispatch();
  const { setQuery } = studyListActions;
  const totalRecords = useSelector(selectStudiesTotalRecords);

  const { page = PAGE_1, limit } = useSelector(selectStudiesQuery);

  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const isPageBiggerThanOne = page && page > PAGE_1;
    const isTotalRecordsLessThanLimitTimesPage =
      page && totalRecords && totalRecords <= (page - PAGE_1) * (limit || 10);
    if (isPageBiggerThanOne && isTotalRecordsLessThanLimitTimesPage) {
      dispatch(setQuery({ ...selectStudiesQuery, page: page - PAGE_1 }));
      storeUserSettings(SEARCH_SELECTION, { page: page - PAGE_1 });
      return;
    }
    if (!totalRecords || !limit) {
      setIsNextDisabled(true);
      setIsPrevDisabled(true);
      return;
    }
    setTotalPages(Math.ceil(totalRecords / limit));
    setIsNextDisabled(page >= totalPages);
    setIsPrevDisabled(page <= 1);
  }, [dispatch, limit, page, totalPages, totalRecords, setQuery]);

  const gotoPage = (pageIndex: number) => {
    dispatch(setQuery({ page: pageIndex }));
    storeUserSettings(SEARCH_SELECTION, { page: pageIndex });
  };

  return { isNextDisabled, isPrevDisabled, gotoPage, currentPage: page, totalPages };
};

export default usePagination;

import { ReactComponent as PersonLockRegular } from '@fluentui/svg-icons/icons/person_lock_16_regular.svg';
import { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { USER_LOGGED_IN } = ReactTestingLibraryDataProperties;
const { ON_NEUTRAL_BACKGROUND } = colors;

export const UserLoggedIn: FC<Icon> = ({ size = 1 }) => (
  <Container size={size} $testId={USER_LOGGED_IN}>
    <PersonLockRegular fill={ON_NEUTRAL_BACKGROUND} />
  </Container>
);

export default UserLoggedIn;

import { dismissCircle12Regular } from '@annaliseai/anna-icons';
import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { TRASH } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY, PRIMARY } = colors;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 4.6rem;
  top: 0.3rem;
  border-radius: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${ON_PRIMARY};
`;

type TrashProps = {
  onTrash: () => Promise<void>;
};

const Trash: FC<TrashProps> = ({ onTrash }) => {
  const _onTrash = async (event: KeyboardEvent | MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    await onTrash();
  };

  return (
    <Container tabIndex={0} role="button" onKeyDown={_onTrash} onClick={_onTrash} data-testid={TRASH}>
      <IconWrapper svg={dismissCircle12Regular} fill={PRIMARY} size={0.7} />
    </Container>
  );
};

export default Trash;

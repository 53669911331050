import { size } from '@annaliseai/anna-design-tokens';
import AnnaWindowingBar from '@annaliseai/windowing-bar';
import styled from 'styled-components';

const { SPACING_04 } = size;

export const WindowingContainer = styled.div`
  align-self: center;
  justify-self: right;
`;

const WindowingBar = styled(AnnaWindowingBar)`
  height: 22.5rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: ${SPACING_04}px;
`;

export default WindowingBar;

import { cloudArrowUp, cloudArrowUpFilled, studiesFilled, studiesRegular } from '@annaliseai/anna-icons';
import { TabsId } from 'components/CxrUploadTabs/types';
import colors from './colors';

const { ON_SECONDARY, ON_PRIMARY } = colors;
const { VIEW_SAMPLE_STUDIES, UPLOAD_YOUR_OWN_IMAGES } = TabsId;

const cxrUploadsTabs = [
  {
    id: VIEW_SAMPLE_STUDIES,
    label: 'View sample studies',
    regularIcon: studiesRegular,
    activeIcon: studiesFilled,
    size: 1.5,
    fill: ON_PRIMARY,
  },
  {
    id: UPLOAD_YOUR_OWN_IMAGES,
    label: 'Upload your own images',
    regularIcon: cloudArrowUp,
    activeIcon: cloudArrowUpFilled,
    size: 2,
    fill: ON_SECONDARY,
  },
];

export default cxrUploadsTabs;

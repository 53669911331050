import styled from 'styled-components';

export const HelpModalTitle = styled.div`
  font-size: 1.25em;
  padding-bottom: 0.5rem;
`;

export const HelpModalParagraph = styled.p`
  margin: 0.5em 0;
  text-align: center;
`;

import { ChangeEventHandler, Fragment, useEffect, useRef, useState } from 'react';
import { ColumnSelectButton, ColumnSelectContainer } from 'components/ColumnSelect/ColumnSelect.styles';
import Checkbox from 'components/Menu/Checkbox/Checkbox';
import Menu from 'components/Menu/Menu';
import { Divider } from 'components/Menu/Menu.styles';
import { MenuPosition } from 'components/Menu/Menu.types';
import colors from 'constants/colors';
import { APP } from 'constants/sizes';
import Keys from 'enums/Keys';
import Columns from 'icons/Columns';
import { PER_OPTION_HEIGHT } from './constants';

const { ON_SECONDARY } = colors;
const { TOP, BOTTOM } = MenuPosition;
const { HEADER_HEIGHT } = APP;

type Column = {
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isDisabled: boolean;
  id: string;
  label: string;
};

const ColumnSelect = ({ columns }: { columns: Column[] }): JSX.Element => {
  const { TAB_KEY } = Keys;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(TOP);
  const columnSelectRef = useRef<HTMLDivElement>(null);

  // Dynamic distance based on number of columns/options plus the 'All' option
  const dynamicHeight = columns.length * PER_OPTION_HEIGHT + PER_OPTION_HEIGHT;
  // convert rem to pixels
  const minDistanceFromTop = (dynamicHeight + HEADER_HEIGHT) * 16;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (columnSelectRef.current && !columnSelectRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    // Close the menu when the user tabs off the menu (possibly temporary until proper keyboard nav implemented)
    const handleTab = (event: KeyboardEvent) => {
      if (event.key === TAB_KEY) {
        if (columnSelectRef.current && !columnSelectRef.current.contains(event.target as Node)) {
          setIsMenuOpen(false);
        }
      }
    };

    document.addEventListener('keyup', handleTab);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleTab);
    };
  }, [TAB_KEY]);

  const handleClick = () => {
    const distanceFromTop = columnSelectRef?.current?.getBoundingClientRect().top;
    setMenuPosition(distanceFromTop && distanceFromTop < minDistanceFromTop ? BOTTOM : TOP);
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <ColumnSelectContainer ref={columnSelectRef}>
      <ColumnSelectButton $isActive={isMenuOpen} onClick={handleClick}>
        <Columns color={ON_SECONDARY} />
      </ColumnSelectButton>
      <Menu position={menuPosition} isVisible={isMenuOpen} dynamicHeight={dynamicHeight}>
        {columns.map((column: Column, idx) => {
          const { id } = column;
          return (
            <Fragment key={`${id}-checkbox`}>
              <Checkbox {...column} />
              {/*Add divider after the first child - 'All' option */}
              {idx === 0 && <Divider />}
            </Fragment>
          );
        })}
      </Menu>
    </ColumnSelectContainer>
  );
};
export default ColumnSelect;

import styled from 'styled-components';
import colors from 'constants/colors';

const { PRIMARY, PRIMARY_CONTAINER } = colors;
const LightButton = styled.button`
  padding: 0 1.5rem;
  background-color: ${PRIMARY_CONTAINER};
  color: ${PRIMARY};
  border-radius: 0.5em;
  border: 0;
  height: 100%;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default LightButton;

import { colors as annaColors } from '@annaliseai/anna-design-tokens';

const colors = {
  ...annaColors,
  CURRENT_COLOR: 'currentColor',
  LOCALISATION_OVERLAY: 'rgba(219, 0, 255, 0.35)',
  DROP_SHADOW: 'rgba(0, 0, 0, 0.25)',

  // Colors for the Worklist rows and priority pills
  RED_1: '#FF797E',
  RED_2: '#FF2E00',
  YELLOW_1: '#FFEF9E',
  YELLOW_2: '#FEF111',
  AQUA_1: '#A5E1FF',
  AQUA_2: '#64C9FA',

  // Traffic light colors for the Admin screen
  GREEN: '#57c81b',
  YELLOW: '#FFc800',
  RED: '#FF0000',

  // TO BE REMOVED WHEN MIGRATING TO ANNA COLOUR TOKENS
  RED_1_HOVER: '#FF8F93',
  YELLOW_1_HOVER: '#FFF3B2',
  SECONDARY_CONTAINER_HOVER: '#3D3D3D',
  ON_SECONDARY_HOVER: '#D6D6D6',
  SECONDARY_HOVER: '',
};

export default colors;

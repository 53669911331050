import Button from '@annaliseai/button';
import Dialog from '@annaliseai/dialog';
import TextArea from '@annaliseai/textarea';
import { AxiosError } from 'axios';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deleteStudy, { ERRORS_MAPPING } from 'api/queries/deleteStudies';
import { UserInput } from 'components/Form/Form.types';
import { sanitizeSearchQueryParams } from 'components/SearchFilter/SearchFilter.utils';
import {
  BUTTON_TEXT_CANCEL,
  BUTTON_TEXT_DELETE,
  BUTTON_TEXT_OK,
  BUTTON_TEXT_OPENER_ICON,
  BUTTON_TEXT_OPENER_TITLE,
  DIALOG_FOOTER_ICON_LOADING,
  DIALOG_FOOTER_ICON_SUCCESS,
  DIALOG_FOOTER_PROCESSING,
  DIALOG_FOOTER_SUCCESS,
  DIALOG_HEADER_ICON,
  DIALOG_HEADER_TEXT,
  DIALOG_SUCCESS_CLOSE_DELAY,
  DIALOG_TEXTAREA_MAX_CHARS,
  DIALOG_TEXTAREA_PLACEHOLDER,
  DIALOG_TEXT_ACC,
  DIALOG_TEXT_CONFIRMATION,
  DIALOG_TEXT_ERROR,
  DIALOG_TEXT_NAME,
} from 'components/WorklistRowActions/constants';
import {
  DialogBodySecondary,
  DialogBodyPrimary,
  DeleteButton,
  DialogChildren,
  WorklistRowActionsCell,
} from 'components/WorklistRowActions/WorklistRowActions.styles';
import buildErrorMessage from 'components/WorklistRowActions/WorklistRowActions.utils';
import { selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';

const { fetch } = studyListActions;

type WorklistRowActionsProps = {
  accessionNumber: string;
  patientName?: string;
};

const WorklistRowActions = ({ accessionNumber, patientName }: WorklistRowActionsProps): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDeleteError, setIsDeleteError] = useState<boolean>(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState<boolean>(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);
  const [openerButtonDisabled, setOpenerButtonDisabled] = useState<boolean>(false);
  const [reasonDisabled, setReasonDisabled] = useState<boolean>(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>('');
  const [deleteErrorCode, setDeleteErrorCode] = useState<string>('');
  const [reasonText, setReasonText] = useState<string>('');
  const [footerText, setFooterText] = useState<string>('');
  const [footerIcon, setFooterIcon] = useState({});
  const [headerText, setHeaderText] = useState<string>(DIALOG_HEADER_TEXT);

  const dispatch = useDispatch();
  const studiesQuery = useSelector(selectStudiesQuery);

  const openerRef = useRef<HTMLTableCellElement | null>(null);

  const handleDialogOpen = (e: UserInput): void => {
    e.stopPropagation();
    // Note: event was bubbling up to dialog buttons, so added check to ensure the user has actually clicked the opener element
    if (openerRef?.current?.contains(e?.target as HTMLElement)) {
      setIsDialogOpen(true);
    }
  };

  const resetAndCloseDialog = (): void => {
    setIsDialogOpen(false);
    setDeleteErrorMessage('');
    setDeleteErrorCode('');
    setReasonText('');
    setHeaderText(DIALOG_HEADER_TEXT);
    setFooterText('');
    setFooterIcon({});
    setCancelButtonDisabled(false);
    setDeleteButtonDisabled(false);
    setOpenerButtonDisabled(false);
    setReasonDisabled(false);
    setIsDeleteError(false);
  };

  const handleDeleteStudy = async (): Promise<void> => {
    // Set loading states
    setFooterText(DIALOG_FOOTER_PROCESSING);
    setFooterIcon(DIALOG_FOOTER_ICON_LOADING);
    setCancelButtonDisabled(true);
    setDeleteButtonDisabled(true);
    setReasonDisabled(true);

    try {
      await deleteStudy(accessionNumber, reasonText);

      // Set success states
      setFooterText(DIALOG_FOOTER_SUCCESS);
      setFooterIcon(DIALOG_FOOTER_ICON_SUCCESS);
      setOpenerButtonDisabled(true);

      setTimeout(() => {
        const sanitizedQueryParams = sanitizeSearchQueryParams(studiesQuery);
        dispatch(fetch(sanitizedQueryParams));
        resetAndCloseDialog();
      }, DIALOG_SUCCESS_CLOSE_DELAY);
    } catch (error) {
      const errorDetails = ERRORS_MAPPING[(error as AxiosError)?.response?.status || 500];

      // Set error states
      setIsDeleteError(true);
      setHeaderText(errorDetails.title);
      setDeleteErrorMessage(buildErrorMessage(errorDetails));
      setDeleteErrorCode(errorDetails.errorId);
    }
  };

  const handleReasonTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const reason = e?.target?.value;
    reason.length <= DIALOG_TEXTAREA_MAX_CHARS && setReasonText(reason);
  };

  return (
    <WorklistRowActionsCell ref={openerRef} onClick={handleDialogOpen}>
      <DeleteButton
        disabled={openerButtonDisabled}
        icon={BUTTON_TEXT_OPENER_ICON}
        title={BUTTON_TEXT_OPENER_TITLE}
        variant="outline-removed"
      />
      <Dialog
        header={{ icon: DIALOG_HEADER_ICON, text: headerText }}
        isDismissible={false}
        footer={
          isDeleteError
            ? {
                buttons: [
                  <Button key={'primary'} variant="primary" onClick={resetAndCloseDialog}>
                    {BUTTON_TEXT_OK}
                  </Button>,
                ],
              }
            : {
                buttons: [
                  <Button
                    key={'secondary'}
                    variant="outlined"
                    onClick={resetAndCloseDialog}
                    disabled={cancelButtonDisabled}
                  >
                    {BUTTON_TEXT_CANCEL}
                  </Button>,
                  <Button key={'primary'} variant="primary" onClick={handleDeleteStudy} disabled={deleteButtonDisabled}>
                    {BUTTON_TEXT_DELETE}
                  </Button>,
                ],
                icon: footerIcon,
                text: footerText,
              }
        }
        isOpen={isDialogOpen}
        onClose={resetAndCloseDialog}
      >
        {isDeleteError ? (
          <DialogChildren>
            {deleteErrorMessage && <DialogBodyPrimary>{deleteErrorMessage}</DialogBodyPrimary>}
            <DialogBodySecondary>
              {deleteErrorCode && <p>{`${DIALOG_TEXT_ERROR} ${deleteErrorCode}`}</p>}
            </DialogBodySecondary>
          </DialogChildren>
        ) : (
          <DialogChildren>
            <DialogBodyPrimary>{DIALOG_TEXT_CONFIRMATION}</DialogBodyPrimary>
            <DialogBodySecondary>
              {patientName && <p>{`${DIALOG_TEXT_NAME} ${patientName}`}</p>}
              {accessionNumber && <p>{`${DIALOG_TEXT_ACC} ${accessionNumber}`}</p>}
            </DialogBodySecondary>
            <TextArea
              disabled={reasonDisabled}
              onChange={handleReasonTextChange}
              placeholder={DIALOG_TEXTAREA_PLACEHOLDER}
              resize="none"
              rows={3}
              value={reasonText}
            />
          </DialogChildren>
        )}
      </Dialog>
    </WorklistRowActionsCell>
  );
};

export default WorklistRowActions;

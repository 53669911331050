import { ReactComponent as TargetArrowRegular } from '@fluentui/svg-icons/icons/target_arrow_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ICON_ONBOARDING_SUBJECTIVE } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

const OnboardSubjective: FC = () => (
  <TargetArrowRegular width={32} height={32} fill={ON_PRIMARY} data-testid={ICON_ONBOARDING_SUBJECTIVE} />
);

export default OnboardSubjective;

import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_BUTTON, NOT_VIEWED_BUTTON } from 'components/ViewedFilter/constants';
import {
  FilterButton,
  FilterButtonsContainer,
  FilterLabel,
  ViewedFilterContainer,
} from 'components/ViewedFilter/ViewedFilter.styles';
import CookieKeys from 'enums/CookieKeys';
import Pages from 'enums/Pages';
import { getUserSettings, storeUserSettings } from 'helpers/cookies/userSettingsHelper';
import { selectStudiesQuery } from 'selectors/studyList/selectStudyList';
import { studyListActions } from 'slices/studyListSlice';

const { SEARCH_SELECTION } = CookieKeys;
const { PAGE_1 } = Pages;

const { setQuery } = studyListActions;

const ViewedFilter = (): ReactElement => {
  const dispatch = useDispatch();
  const studiesQuery = useSelector(selectStudiesQuery);
  const storedViewedFilter = getUserSettings(SEARCH_SELECTION) || {};
  const { viewed: savedViewed } = storedViewedFilter;
  const { viewed = savedViewed } = studiesQuery;

  const isAllSelected = viewed === undefined;
  const isNotViewedSelected = viewed === 'false' || viewed === false;

  const handleOnClick = (viewed: boolean | undefined) => (selected: boolean) => () => {
    if (!selected) {
      const saveableFilters = { ...studiesQuery, viewed, page: PAGE_1 };
      dispatch(setQuery(saveableFilters));
      storeUserSettings(SEARCH_SELECTION, saveableFilters);
    }
  };

  return (
    <ViewedFilterContainer>
      <FilterLabel>Show</FilterLabel>
      <FilterButtonsContainer>
        <FilterButton $isSelected={isAllSelected} onClick={handleOnClick(undefined)(isAllSelected)}>
          {ALL_BUTTON}
        </FilterButton>
        <FilterButton $isSelected={isNotViewedSelected} onClick={handleOnClick(false)(isNotViewedSelected)}>
          {NOT_VIEWED_BUTTON}
        </FilterButton>
      </FilterButtonsContainer>
    </ViewedFilterContainer>
  );
};

export default ViewedFilter;

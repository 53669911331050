import styled from 'styled-components';
import colors from 'constants/colors';
import { APP, BREAK_POINTS } from 'constants/sizes';
import LogoSizes from 'enums/LogoSizes';

const { SAMPLE_SCREEN_SMALL_MAX_WIDTH, SAMPLE_SCREEN_LARGE_MIN_WIDTH, IPAD_11_BREADTH } = BREAK_POINTS;
const { HEADER_HEIGHT } = APP;
const { L } = LogoSizes;
const { NEUTRAL_OUTLINE } = colors;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: black;
  justify-content: space-between;
  max-width: ${SAMPLE_SCREEN_SMALL_MAX_WIDTH}px;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    max-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH - 1}px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  height: 100%;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: ${(HEADER_HEIGHT - L + 0.625) / 2}rem;
  left: 1rem;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
  font-size: 0.8rem;
  margin: 0;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.125rem;
    margin: 0 3rem;
  }
`;

export const BottomRowItem = styled.div<{ isPortrait: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  @media (max-width: ${IPAD_11_BREADTH}px) {
    &:nth-child(3) {
      ${({ isPortrait }) => isPortrait && `margin: 1rem auto;`}
    }
  }
  margin: 0.7rem 0 0 0.1rem;

  &:not(:last-child) {
    padding-right: 1.5em;
    border-right: 1px solid ${NEUTRAL_OUTLINE};
  }
  &:not(:first-child) {
    padding-left: 1.4em;
  }
`;

export const TextContainer = styled.div<{ isPortrait?: boolean }>`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 0 0.5rem;
  flex: 1 1 25%;
  ${({ isPortrait }) => isPortrait && `margin: 0.5rem;`}
  @media (max-width: ${IPAD_11_BREADTH}px) {
    ${({ isPortrait }) => isPortrait && `text-align: center;`}
  }
`;

export const Heading = styled.div<{ isPortrait?: boolean }>`
  max-width: ${SAMPLE_SCREEN_SMALL_MAX_WIDTH}px;
  margin: 0 0.5rem;
  font-size: 1.25rem;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    max-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH - 1}px;
    margin: 0 3.5rem;
    font-size: 2.5rem;
  }
  ${({ isPortrait }) => isPortrait && `text-align: center;`}
`;

export const SubHeading = styled(Heading)`
  font-size: 1rem;
`;

export const Text = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.25rem;
  }
`;

export const TextGroup = styled.div`
  margin: 0 0.5rem;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    margin: 0 3.5rem;
  }
`;

import styled, { css } from 'styled-components';
import colors from 'constants/colors';
import { APP } from 'constants/sizes';
import { HeaderContainerProps } from './Header';

const { HEADER_HEIGHT } = APP;
const { SECONDARY, ON_NEUTRAL_BACKGROUND, ON_NEUTRAL_BACKGROUND_VARIANT } = colors;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const Container = styled.nav<HeaderContainerProps>`
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}rem;
  align-items: center;
  padding: 0 2rem;
  grid-area: nav;
  ${({ $showBorderBottom }) =>
    $showBorderBottom &&
    css`
      border-bottom: 0.06rem ${SECONDARY} solid;
    `}
`;

export const HeaderNotification = styled.div`
  color: ${ON_NEUTRAL_BACKGROUND_VARIANT};
  font-size: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const NavigationContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 100%;
`;

export const UserSignedInContainer = styled.div`
  color: ${ON_NEUTRAL_BACKGROUND};
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.25rem;
`;

import { imageLoaderPrefixSeparator } from 'constants/imageLoaderPrefixesMapping';
import CornerstoneLoaderPrefixes from 'enums/CornerstoneLoaderPrefixes';
import CornerstoneImageId from 'types/cornerstone/CornerstoneImageId';

export const createImageId = (loaderPrefix: CornerstoneLoaderPrefixes, payload: string): CornerstoneImageId =>
  `${loaderPrefix}${imageLoaderPrefixSeparator}${payload}` as CornerstoneImageId;

export const getImageIdPayload = (imageId: CornerstoneImageId): string => {
  // get possible prefix values
  const possiblePrefixes = Object.values(CornerstoneLoaderPrefixes);
  // strip the prefix and separator, e.g. "web://"
  const prefixRegex = RegExp(`^(${possiblePrefixes.join('|')})${imageLoaderPrefixSeparator}`);
  // strip the bytes range, e.g. "10450|10650|"
  const bytesRegex = /^.*?\|.*?\|/;

  return imageId.replace(prefixRegex, '').replace(bytesRegex, '');
};

import { ReactElement } from 'react';
import {
  AboutModalInfoPanel,
  AboutContentWrapper,
  AboutIcon,
} from 'components/Menu/MenuLink/AboutModal/AboutModal.styles';
import { supportItems } from 'components/Menu/MenuLink/AboutModal/constants';

const InfoPanel = (): ReactElement => (
  <AboutModalInfoPanel>
    {supportItems.infoPanelItems.map(({ icon, text }, index) => (
      <AboutContentWrapper key={index}>
        <AboutIcon>{icon}</AboutIcon>
        {text}
      </AboutContentWrapper>
    ))}
  </AboutModalInfoPanel>
);

export default InfoPanel;

import React, { FC, SyntheticEvent } from 'react';
import styled, { DataAttributes } from 'styled-components';
import FindingBadge from 'components/FindingBadge';
import FindingGroup, { FindingGroupFindingProps, FindingGroupProps } from 'components/FindingGroup';
import RelevantFindingGroups from 'components/RelevantFindingGroups';
import {
  Container,
  FindingContainer,
  Header,
  HeaderContainer,
  TopSidebarContainer,
} from 'components/sidebar/Sidebar.styles';
import { PrimarySidebarSection, SecondarySidebarSection } from 'components/sidebar/StudySections';
import TopSidebar, { TopSidebarProps } from 'components/sidebar/TopSidebar';
import configuration from 'configuration';
import colors from 'constants/colors';
import FindingInteractionTypes from 'enums/FindingInteractionTypes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { StudyInformation, StudyInformationWithTitles } from 'helpers/formatDisplayInformation';
import { PatientInfoWithTitles } from 'helpers/formatPatientInformation';
import getFindingInteractionType from 'helpers/getFindingInteractionType';
import { PatientInformation } from 'types/DisplayStudyInformation';
import { FindingGroups } from 'types/finding/FindingGroup';

const { HOVER } = FindingInteractionTypes;
const { SIDEBAR, SIDEBAR__IRRELEVANT_FINDINGS } = ReactTestingLibraryDataProperties;
const { PRIMARY, ON_PRIMARY } = colors;

const IrrelevantFindingGroupContainer = styled.div.attrs<DataAttributes>({
  'data-testid': SIDEBAR__IRRELEVANT_FINDINGS,
})``;

export type SidebarStudyProps = Required<PatientInformation> &
  Required<Pick<StudyInformation, 'studyDateTime' | 'description'>> &
  PatientInfoWithTitles &
  StudyInformationWithTitles & { studyIdentifiers: string };

export type SidebarProps = {
  topSidebarProps: TopSidebarProps;
  studyInformation: SidebarStudyProps;
  headerText: string;
  findingCount: number;
  findingGroups: FindingGroups | null;
  findingProps: Omit<FindingGroupFindingProps, 'shouldOnMouseOverInteract'>;
  findingGroupProps: Pick<FindingGroupProps, 'activeFindingId'>;
  shouldOnMouseOverInteract?: (event: SyntheticEvent<EventTarget, MouseEvent>) => boolean;
};

const Sidebar: FC<SidebarProps> = ({
  topSidebarProps,
  studyInformation,
  headerText,
  findingCount,
  findingGroups,
  findingProps,
  findingGroupProps,
  shouldOnMouseOverInteract = () => true,
}) => {
  const onMouseOver = (event: SyntheticEvent<EventTarget, MouseEvent>) => {
    if (getFindingInteractionType() !== HOVER) {
      return;
    }

    if (!shouldOnMouseOverInteract(event)) {
      return;
    }
  };

  return (
    <Container data-testid={SIDEBAR}>
      <TopSidebarContainer>
        <TopSidebar {...topSidebarProps} />
      </TopSidebarContainer>

      <PrimarySidebarSection {...studyInformation} />

      <HeaderContainer>
        <Header>{headerText}</Header>
        <FindingBadge color={PRIMARY} backgroundColor={ON_PRIMARY}>
          {findingCount || '-'}
        </FindingBadge>
      </HeaderContainer>

      <SecondarySidebarSection {...studyInformation} />

      <FindingContainer>
        {findingGroups && (
          <>
            <RelevantFindingGroups
              relevantFindingGroups={findingGroups.relevantFindingGroups}
              findingProps={{
                ...findingProps,
                shouldOnMouseOverInteract,
              }}
              findingGroupProps={findingGroupProps}
            />
            {configuration.isDemo && (
              <IrrelevantFindingGroupContainer onMouseOver={onMouseOver}>
                <FindingGroup
                  findingGroup={findingGroups.irrelevantFindingGroup}
                  findingProps={{
                    ...findingProps,
                    shouldOnMouseOverInteract,
                  }}
                  {...findingGroupProps}
                />
              </IrrelevantFindingGroupContainer>
            )}
          </>
        )}
      </FindingContainer>
    </Container>
  );
};

export default Sidebar;

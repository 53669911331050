import { colors, size } from '@annaliseai/anna-design-tokens';
import Button from '@annaliseai/button';
import styled from 'styled-components';

const { SECONDARY, ON_SECONDARY, ON_SECONDARY_CONTAINER } = colors;
const { SPACING_03, SPACING_06 } = size;

export const WorklistRowActionsCell = styled.td`
  background-color: ${SECONDARY};
  box-sizing: border-box;
  padding: ${SPACING_03}px;
  position: relative;
  max-width: 2rem;
  width: 2rem;
  white-space: unset;
`;

export const DeleteButton = styled(Button)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const DialogChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_06}px;
  font-size: 0.875rem;
`;

export const DialogBodyPrimary = styled.p`
  color: ${ON_SECONDARY_CONTAINER};
`;

export const DialogBodySecondary = styled.div`
  color: ${ON_SECONDARY};
`;

import React, { FC } from 'react';
import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import { BREAK_POINTS } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import OnboardCardType from 'types/OnboardCardType';

const { PRIMARY_CONTAINER } = colors;
const { ONBOARDING_CARD_CONTAINER } = ReactTestingLibraryDataProperties;
const { IPAD_MINI_BREADTH } = BREAK_POINTS;
const DescriptiveCardContainer = styled.div.attrs<DataAttributes>({
  'data-testid': ONBOARDING_CARD_CONTAINER,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 2em;

  @media (max-width: ${IPAD_MINI_BREADTH}px) {
    margin: 0.5em 1em;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 2rem;
  text-align: center;
  padding: 0.25em 0;
`;

const Description = styled.div`
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${PRIMARY_CONTAINER};
`;

const OnboardCard: FC<OnboardCardType> = ({ description, title, Logo }) => {
  return (
    <DescriptiveCardContainer>
      <Logo />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </DescriptiveCardContainer>
  );
};

export default OnboardCard;

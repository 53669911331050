import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import { LINK_PRIVACY_POLICY } from 'constants/links';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { retrieveAiGdpr5, storeAiGdpr5 } from 'helpers/cookies/aiGdpr5Helper';
import retrieveAnalyticsUserId from 'helpers/cookies/analyticsUserHelper';
import { useMatomo } from 'matomo/react';
import Popup from './Popup';

const { ON_NEUTRAL_BACKGROUND, ON_NEUTRAL_BACKGROUND_VARIANT, NEUTRAL_BACKGROUND_INVERT, SECONDARY } = colors;

const { COOKIES_TOGGLE_CONTAINER } = ReactTestingLibraryDataProperties;

const CookiesToggleContainer = styled.div.attrs<DataAttributes>({
  'data-testid': COOKIES_TOGGLE_CONTAINER,
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-between;
  font-size: 14px;
`;

const Paragraph = styled.p`
  margin: auto 0;
`;

const Link = styled.a`
  text-decoration-line: underline;
`;

const ButtonSpan = styled.span`
  margin-left: 0.5em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 1rem;
  margin: auto 0;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 9em;
  height: 2.2em;
  border-radius: 10px;
`;

const OKButton = styled(Button)`
  background: ${ON_NEUTRAL_BACKGROUND};

  &:hover {
    background: ${ON_NEUTRAL_BACKGROUND_VARIANT};
    color: ${NEUTRAL_BACKGROUND_INVERT};
  }

  &:focus {
    background: ${SECONDARY};
  }
`;

const OptOutButton = styled(Button)`
  background: ${NEUTRAL_BACKGROUND_INVERT};

  &:hover {
    background: ${ON_NEUTRAL_BACKGROUND};
  }

  &:focus {
    background: ${SECONDARY};
  }
`;

const CookiesToggle: FC = () => {
  const [shouldDisplay, setShouldDisplay] = useState(retrieveAiGdpr5() === undefined);
  const { pushInstruction } = useMatomo();
  const optin = retrieveAiGdpr5() === 'optin';

  useEffect(() => {
    if (optin) {
      pushInstruction('forgetUserOptOut');
    } else {
      pushInstruction('optUserOut');
    }
  }, [optin, pushInstruction]);

  const okButtonClick = () => {
    storeAiGdpr5(true);
    const userId = retrieveAnalyticsUserId();
    pushInstruction('setUserId', userId);
    pushInstruction('forgetUserOptOut');
    setShouldDisplay(false);
  };

  const optoutButtonClick = () => {
    storeAiGdpr5(false);
    pushInstruction('optUserOut');
    setShouldDisplay(false);
  };

  return (
    <>
      {shouldDisplay && (
        <Popup>
          <CookiesToggleContainer>
            <Paragraph>We use minimal cookies to respect your privacy.</Paragraph>
            <Paragraph>
              We collect anonymous information about visitors, interactions and devices to improve your experience.{' '}
            </Paragraph>
            <Paragraph>
              For details, see our{' '}
              <Link href={LINK_PRIVACY_POLICY} target="_blank">
                Privacy Policy
              </Link>{' '}
            </Paragraph>
            <ButtonsContainer>
              <OKButton onClick={okButtonClick}>
                <FontAwesomeIcon icon={faCheckCircle} />
                <ButtonSpan>OK</ButtonSpan>
              </OKButton>
              <OptOutButton onClick={optoutButtonClick}>Opt out</OptOutButton>
            </ButtonsContainer>
          </CookiesToggleContainer>
        </Popup>
      )}
    </>
  );
};

export default CookiesToggle;

import { ReactComponent as BrainCircuitFilled } from '@fluentui/svg-icons/icons/brain_circuit_20_filled.svg';
import { ReactComponent as BrainCircuitRegular } from '@fluentui/svg-icons/icons/brain_circuit_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';
import Container from './Container';

const { BRAIN } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

export const Brain: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={BRAIN}>
    <BrainCircuitRegular fill={color} fillOpacity={fillOpacity} />
  </Container>
);

export const BrainSolid: FC<Icon> = ({ size = 1.5, color = ON_PRIMARY, fillOpacity = 1 }) => (
  <Container size={size} $testId={BRAIN}>
    <BrainCircuitFilled fill={color} fillOpacity={fillOpacity} />
  </Container>
);

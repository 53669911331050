import { Status } from '@annaliseai/api-specifications';
import styled, { DataAttributes } from 'styled-components';
import { buttonBaseStyle } from 'components/Form/Form.styles';
import { isProcessing } from 'components/WorklistTable/utils/WorklistTable.utils';
import { CriticalityConfig, StatusConfig } from 'components/WorklistTable/WorklistTypes';
import colors from 'constants/colors';
import { BREAK_POINTS, FONT_WEIGHTS } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Alert from 'icons/Alert';

const {
  AQUA_2,
  FOCUS,
  SECONDARY_ACTION,
  ON_NEUTRAL_BACKGROUND,
  SECONDARY_CONTAINER,
  SECONDARY_CONTAINER_HOVER,
  ON_PRIMARY,
  RED_1,
  RED_1_HOVER,
  RED_2,
  SECONDARY,
  SECONDARY_HOVER,
  ON_SECONDARY,
  ON_SECONDARY_HOVER,
  YELLOW_1,
  YELLOW_1_HOVER,
  YELLOW_2,
} = colors;
const { MEDIUM_DESKTOP, SMALL_DESKTOP } = BREAK_POINTS;
const { REGULAR } = FONT_WEIGHTS;
const { PRIORITY_TAG } = ReactTestingLibraryDataProperties;

const padding1 = '0.25rem';
const padding2 = '0.5rem';
const padding3 = '0.75rem';
const padding4 = '1rem';

export const rowStylingConfig: CriticalityConfig = {
  1: {
    backgroundColor: RED_1,
    backgroundColorHover: RED_1_HOVER,
    tagColor: RED_2,
    color: SECONDARY_CONTAINER,
    icon: <Alert color={SECONDARY_CONTAINER} size={1} />,
  },
  2: {
    backgroundColor: YELLOW_1,
    backgroundColorHover: YELLOW_1_HOVER,
    tagColor: YELLOW_2,
    color: SECONDARY_CONTAINER,
    icon: <Alert color={SECONDARY_CONTAINER} size={1} />,
  },
  3: {
    backgroundColor: SECONDARY_CONTAINER,
    backgroundColorHover: SECONDARY_CONTAINER_HOVER,
    tagColor: AQUA_2,
    color: ON_SECONDARY,
  },
};

export const rowStatusConfig: StatusConfig = {
  PENDING: {
    backgroundColor: SECONDARY,
    backgroundColorHover: SECONDARY_HOVER,
    color: SECONDARY_ACTION,
    fontStyle: 'italic',
  },
  IN_PROGRESS: {
    backgroundColor: SECONDARY,
    backgroundColorHover: SECONDARY_HOVER,
    color: SECONDARY_ACTION,
    fontStyle: 'italic',
  },
  COMPLETED_ERROR: {
    backgroundColor: ON_SECONDARY,
    backgroundColorHover: ON_SECONDARY_HOVER,
    color: SECONDARY_CONTAINER,
    tagColor: ON_PRIMARY,
  },
  COMPLETED: {
    backgroundColor: SECONDARY_CONTAINER,
    backgroundColorHover: SECONDARY_CONTAINER_HOVER,
    color: ON_SECONDARY,
  },
};

export const Table = styled.table`
  width: 100%;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 0.063rem;
  border-top: 1px solid ${SECONDARY};
  border-bottom: 1px solid ${SECONDARY};

  th {
    padding: 0.8em;
    background-color: transparent;
  }
`;

export const Head = styled.thead`
  color: ${ON_NEUTRAL_BACKGROUND};
  font-size: 0.5rem;
  font-weight: ${REGULAR};
  text-transform: uppercase;
  letter-spacing: 0.013rem;
  @media (min-width: ${SMALL_DESKTOP}px) {
    font-size: 0.563rem;
  }
`;

export const ColumnHeading = styled.th`
  font-weight: 500;
  letter-spacing: 0.08em;

  &:first-child {
    padding-left: ${padding2};
  }

  &:last-child {
    padding-right: ${padding2};
  }

  @media (min-width: ${SMALL_DESKTOP}px) {
    &:first-child {
      padding-left: ${padding4};
    }

    &:last-child {
      padding-right: ${padding2};
    }
  }
`;

export const AIFindingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnHeadingWrapper = styled.span`
  color: ${ON_NEUTRAL_BACKGROUND};
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  display: grid;
`;

export const ColumnHeadingSortButton = styled.button<{ $isSortActive?: boolean }>`
  ${buttonBaseStyle};
  align-items: center;
  color: ${ON_NEUTRAL_BACKGROUND};
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-transform: uppercase;
  white-space: nowrap;

  svg,
  path {
    fill: ${ON_NEUTRAL_BACKGROUND};
  }

  &:hover {
    color: ${ON_PRIMARY};
    cursor: pointer;

    svg,
    path {
      fill: ${ON_PRIMARY};
    }
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px ${FOCUS};
  }

  ${({ $isSortActive }) =>
    $isSortActive &&
    `
    color: ${ON_PRIMARY};

    svg,
    path {
      fill: ${ON_PRIMARY};
    }
  `};
`;

const BaseRow = styled.tr`
  height: 2.262rem;
  max-height: 2.5rem;
  color: ${ON_SECONDARY};
  background-color: transparent;
  @media (min-width: ${SMALL_DESKTOP}px) {
    height: 2.5rem;
  }
`;

export const HeaderRow = styled(BaseRow)``;

export const Body = styled.tbody`
  font-size: 0.563rem;
  font-weight: 500;
  line-height: 0.75rem;
  @media (min-width: ${SMALL_DESKTOP}px) {
    font-size: 0.625rem;
  }
`;

export const Row = styled(BaseRow)<{ rank?: number; status: Status; $hasPriorityFindings?: boolean }>`
  &:hover {
    ${({ status }) => (!isProcessing(status) && `cursor: pointer;`) || `cursor: default;`}
  }

  // Style for completed/error/processing rows with no priority findings
  ${({ status, $hasPriorityFindings }) =>
    status &&
    !$hasPriorityFindings &&
    `
      color: ${rowStatusConfig[status]?.color};
      background-color: ${rowStatusConfig[status]?.backgroundColor};
      font-style: ${rowStatusConfig[status]?.fontStyle || 'initial'};
      &:hover { background-color: ${rowStatusConfig[status]?.backgroundColorHover}; }`}

  // Style for rows with priority findings
  ${({ rank }) =>
    rank &&
    rowStylingConfig[rank] &&
    `
      color: ${rowStylingConfig[rank]?.color};
      background-color: ${rowStylingConfig[rank]?.backgroundColor};
      &:hover { background-color: ${rowStylingConfig[rank]?.backgroundColorHover}; }`}
`;

export const Cell = styled.td`
  position: relative;
  padding: ${padding2};
  white-space: unset;

  &:first-child {
    padding-left: ${padding2};
  }

  &:last-child {
    padding-right: ${padding2};
  }

  @media (min-width: ${SMALL_DESKTOP}px) {
    &:first-child {
      padding-left: ${padding4};
    }

    &:last-child {
      padding-right: ${padding2};
    }
  }
`;

export const CellInterior = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 ${padding4};
`;

export const RightAlignedCellInterior = styled(CellInterior)`
  justify-content: flex-end;
`;

export const DateCellInterior = styled(CellInterior)`
  justify-content: left;
  gap: ${padding2};
`;

export const CellContent = styled.div<{ fontWeight?: number }>`
  align-items: center;
  display: flex;
  gap: ${padding1};
  white-space: nowrap;

  ${({ fontWeight }) => `font-weight: ${fontWeight}`}
  &:first-child {
    text-align: left;
  }
`;
export const PatientName = styled(CellContent)`
  width: 11rem;
  word-break: break-all;
  hyphens: auto;
`;

export const Tag = styled.div.attrs<DataAttributes>({ 'data-testid': PRIORITY_TAG })<{ $tagColor?: string }>`
  align-items: center;
  background-color: ${({ $tagColor = '' }) => $tagColor};
  border-radius: 100px;
  color: BLACK;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  gap: ${padding1};
  line-height: 1.125rem;
  padding: 0 ${padding2};

  @media (min-width: ${SMALL_DESKTOP}px) {
    gap: ${padding2};
    padding: 0.188rem ${padding3};
  }
  @media (min-width: ${MEDIUM_DESKTOP}px) {
    gap: ${padding2};
    padding: ${padding1} ${padding3};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`;

export const TableControls = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0;
  justify-items: center;
  border-top: 1px solid ${SECONDARY};
  border-bottom: 1px solid ${SECONDARY};
  padding: 0.5rem ${padding4};
  align-items: center;
  @media (min-width: ${SMALL_DESKTOP}px) {
    padding: 0.5rem 0;
  }
`;

export const NoResultsContainer = styled.div`
  position: relative;
  margin: 2rem ${padding4};
  text-align: center;
  height: 100%;
  width: 100%;
`;

export const NoResultsNotice = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  gap: 0.5rem;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const HeaderSortIconWrapper = styled.span`
  margin-left: 0.25em;
`;

import { ReactComponent as PersonSettingsRegular } from '@fluentui/svg-icons/icons/person_settings_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ICON_ONBOARDING_CONFIG } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

const OnboardConfig: FC = () => (
  <PersonSettingsRegular width={32} height={32} fill={ON_PRIMARY} data-testid={ICON_ONBOARDING_CONFIG} />
);

export default OnboardConfig;

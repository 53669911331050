import styled, { css } from 'styled-components';
import colors from 'constants/colors';

const { ON_SECONDARY, SECONDARY, FOCUS, NEUTRAL_OUTLINE, ON_NEUTRAL_BACKGROUND_VARIANT } = colors;

// common base style for buttons and menu items
export const ButtonLikeBaseCss = css`
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  height: 100%;
  transition: 0.1s;
  gap: 0.3rem;
  outline-color: transparent;
  border: 0;
  &:focus {
    outline: 0.06rem solid ${FOCUS};
  }
`;

export const ButtonBase = styled.button`
  ${ButtonLikeBaseCss};
  justify-content: center;
`;

export const ButtonNoBorderCss = css`
  color: ${ON_NEUTRAL_BACKGROUND_VARIANT};
  fill: ${ON_NEUTRAL_BACKGROUND_VARIANT};
  background-color: inherit;

  &:hover {
    cursor: pointer;
    color: ${ON_SECONDARY};
    fill: ${ON_SECONDARY};
    background-color: ${SECONDARY};
  }
  &:focus {
    color: ${ON_SECONDARY};
    fill: ${ON_SECONDARY};
    background-color: ${SECONDARY};
  }
  &:active {
    outline-color: transparent;
    color: ${ON_SECONDARY};
    fill: ${ON_SECONDARY};
    background-color: ${SECONDARY};
  }
  &:disabled {
    color: ${NEUTRAL_OUTLINE};
    fill: ${NEUTRAL_OUTLINE};
    cursor: not-allowed;
  }
`;

export const ButtonIconCss = css`
  height: 2rem;
  width: 2rem;
  padding: 0;

  // making it a nice square
  display: grid;
  place-items: center;
`;

export const ButtonSmallCss = css`
  height: 2rem;
  padding: 0.13rem 0.5rem;
`;

export const ButtonMediumCss = css`
  height: 2rem;
  padding: 0.25rem 1rem;
`;

export const ButtonLargeCss = css`
  height: 2.5rem;
  padding: 0.5rem 1rem;
`;

import { ReactElement } from 'react';
import { AboutLogosWrapper, AboutModalSubheader } from 'components/Menu/MenuLink/AboutModal/AboutModal.styles';
import { LOGOS_SUBHEADER, logoItems } from 'components/Menu/MenuLink/AboutModal/constants';

const LogosPanel = (): ReactElement => (
  <>
    <AboutModalSubheader>{LOGOS_SUBHEADER}</AboutModalSubheader>
    <AboutLogosWrapper>
      {logoItems.map(({ icon, title }, index) => (
        <span key={`about-logo-${index}`} title={title}>
          {icon}
        </span>
      ))}
    </AboutLogosWrapper>
  </>
);

export default LogosPanel;

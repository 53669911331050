import styled from 'styled-components';
import colors from 'constants/colors';
import { BREAK_POINTS } from 'constants/sizes';

const { ON_PRIMARY, ON_SECONDARY, ON_NEUTRAL_BACKGROUND } = colors;

const { MEDIUM_DESKTOP, SMALL_DESKTOP, TABLET } = BREAK_POINTS;

export const Container = styled.section`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.8fr;
  grid-template-rows: max-content minmax(0, 1fr);
  gap: 0 0;
  grid-template-areas:
    'nav'
    'main';
`;

export const BodyContainer = styled.div`
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ServiceCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 19rem);
  grid-template-rows: repeat(auto, 7.5rem);
  gap: 0.75rem;
  @media (max-width: ${MEDIUM_DESKTOP}px) {
    grid-template-columns: repeat(3, 19rem);
  }
  @media (max-width: ${SMALL_DESKTOP}px) {
    grid-template-columns: repeat(2, 19rem);
  }
  @media (max-width: ${TABLET}px) {
    grid-template-columns: repeat(1, 19rem);
  }
`;

export const AdminPortalHeader = styled.h2`
  color: ${ON_PRIMARY};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
`;

export const AdminPortalSubHeader = styled.h3`
  color: ${ON_PRIMARY};
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
`;

export const ServicesHeader = styled.h2`
  color: ${ON_PRIMARY};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: normal;
`;

export const ServicesSubHeader = styled.p`
  color: ${ON_NEUTRAL_BACKGROUND};
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ServicesLoadingIndicator = styled.div`
  align-self: center;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessageContainer = styled.div`
  align-self: center;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ErrorMessage = styled.p`
  color: ${ON_SECONDARY};
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 27.5rem;
  margin-top: -5rem;
  text-align: center;
`;

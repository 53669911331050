import { colors } from '@annaliseai/anna-design-tokens';
import {
  DocumentationItemType,
  LogoItemType,
  SupportItemType,
} from 'components/Menu/MenuLink/AboutModal/AboutModal.types';
import {
  EMAIL_SUPPORT,
  ENTERPRISE_PERFORMANCE_GUIDE_TEXT,
  ENTERPRISE_USER_GUIDE_TEXT,
  INFO_BOX_READ_USER_GUIDE,
  INFO_BOX_WARNING,
  LEGAL_NOTICES_TEXT,
  MAIL_TO_HREF,
  PRIVACY_POLICY_TEXT,
} from 'components/Nav/constants';
import {
  LINK_ENTERPRISE_PERFOMANCE_GUIDE,
  LINK_ENTERPRISE_USER_GUIDE,
  LINK_PRIVACY_POLICY,
  LINK_TERMS_CONDITIONS,
} from 'constants/links';
import IsoAlert from 'icons/IsoAlert';
import IsoMd from 'icons/IsoMd';
import IsoUkCa from 'icons/IsoUkCa';
import UserGuide from 'icons/UserGuide';

const { SECONDARY_ACTION } = colors;

export const ABOUT_WEB_VIEWER_TEXT = 'About Web Viewer';
export const ABOUT_WEB_VIEWER_MODAL_SUBTITLE_TEXT = 'Annalise Enterprise clinical decision support';
export const ABOUT_WEB_VIEWER_MODAL_TITLE_TEXT = 'Annalise Web Viewer';
export const DOCUMENTATION_SUBHEADER = 'Documentation';
export const EMAIL_TEXT = 'Email';
export const LOGOS_SUBHEADER = 'Logos';
export const SUPPORT_SUBHEADER = 'Support';
export const UDI_TITLE = 'UDI:';
export const UDI_WEB_VERSION = '*+G140ANNALISEWEBVIEWER10/$$+7WV-1.2.0E*';
export const VERSION_WEB_SUBHEADER = 'Web Viewer Version';
export const VERSION_ENTERPRISE_SUBHEADER = 'Enterprise Version';
export const VERSION_TITLE = 'Version:';

export const KEY_PRIVACY_POLICY = 'privacyPolicy';
export const KEY_USER_GUIDE = 'userGuide';
export const KEY_LEGAL_NOTICES = 'legalNotices';
export const KEY_PERFORMANCE_SPECS = 'performanceSpecifications';

export const documentationItems: DocumentationItemType = [
  {
    key: KEY_USER_GUIDE,
    text: ENTERPRISE_USER_GUIDE_TEXT,
    link: LINK_ENTERPRISE_USER_GUIDE,
  },
  {
    key: KEY_PERFORMANCE_SPECS,
    text: ENTERPRISE_PERFORMANCE_GUIDE_TEXT,
    link: LINK_ENTERPRISE_PERFOMANCE_GUIDE,
  },
  {
    key: KEY_LEGAL_NOTICES,
    text: LEGAL_NOTICES_TEXT,
    link: LINK_TERMS_CONDITIONS,
  },
  {
    key: KEY_PRIVACY_POLICY,
    text: PRIVACY_POLICY_TEXT,
    link: LINK_PRIVACY_POLICY,
  },
];

export const supportItems: SupportItemType = {
  email: EMAIL_SUPPORT,
  mailToHref: MAIL_TO_HREF,
  infoPanelItems: [
    { icon: <IsoAlert />, text: INFO_BOX_WARNING },
    { icon: <UserGuide />, text: INFO_BOX_READ_USER_GUIDE },
  ],
};

export const logoItems: LogoItemType = [
  { icon: <IsoMd color={SECONDARY_ACTION} />, title: '' },
  { icon: <IsoUkCa color={SECONDARY_ACTION} />, title: '' },
];

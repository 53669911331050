import { imageLoaderPrefixSeparator } from 'constants/imageLoaderPrefixesMapping';

export const WORKER_MESSAGE = {
  IS_COMPLETED: 'is-completed',
  ACTIVE_FINDING: 'active-finding',
  UPDATE_UI: 'update-ui',
  INIT: 'init',
  BASE: 'base',
  SEGMENT: 'segment',
};

export const ABORT_ERROR = 'AbortError';

export const SLICE_PREFIXES = {
  PNG16_PREFIX: `png16${imageLoaderPrefixSeparator}`,
  WEB_PREFIX: `web${imageLoaderPrefixSeparator}`,
};

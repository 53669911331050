import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ON_NEUTRAL_BACKGROUND, ON_NEUTRAL_BACKGROUND_VARIANT, FOCUS } = colors;
const { FOOTER } = ReactTestingLibraryDataProperties;

export const FooterContainer = styled.footer.attrs<DataAttributes>({
  'data-testid': FOOTER,
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  height: 5rem;
  width: 100%;
`;

export const ItemsContainer = styled.div`
  display: flex;
  gap: 2rem;
  color: ${ON_NEUTRAL_BACKGROUND};
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const FooterLink = styled.a`
  text-decoration: underline;
  outline-color: transparent;

  &:focus {
    border-radius: 0.5rem;
    outline: 0.06rem solid ${FOCUS};
    color: ${ON_NEUTRAL_BACKGROUND_VARIANT};
  }
  &:hover {
    color: ${ON_NEUTRAL_BACKGROUND_VARIANT};
  }
`;

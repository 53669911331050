import { ReactElement } from 'react';
import { AboutModalSubheader, AboutContentWrapper } from 'components/Menu/MenuLink/AboutModal/AboutModal.styles';
import { VersionPanelProps } from 'components/Menu/MenuLink/AboutModal/AboutModal.types';
import { VERSION_TITLE, UDI_TITLE } from 'components/Menu/MenuLink/AboutModal/constants';

const VersionPanel = ({ subheader, version, udi }: VersionPanelProps): ReactElement => (
  <>
    <AboutModalSubheader>{subheader}</AboutModalSubheader>
    <AboutContentWrapper>
      {VERSION_TITLE} {version}
    </AboutContentWrapper>
    <AboutContentWrapper>
      {UDI_TITLE} {udi}
    </AboutContentWrapper>
  </>
);

export default VersionPanel;

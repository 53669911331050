import { ReactElement } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';

const { ON_PRIMARY } = colors;
const { ICON_CALENDAR } = ReactTestingLibraryDataProperties;

const Save = ({ color = ON_PRIMARY }: Icon): ReactElement => (
  <svg data-testid={ICON_CALENDAR} viewBox="0 0 10 10" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M2 4.5C2 4.22386 2.22386 4 2.5 4C2.77614 4 3 4.22386 3 4.5C3 4.77614 2.77614 5 2.5 5C2.22386 5 2 4.77614 2 4.5ZM2.5 6C2.22386 6 2 6.22386 2 6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5C3 6.22386 2.77614 6 2.5 6ZM4 4.5C4 4.22386 4.22386 4 4.5 4C4.77614 4 5 4.22386 5 4.5C5 4.77614 4.77614 5 4.5 5C4.22386 5 4 4.77614 4 4.5ZM4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7C4.77614 7 5 6.77614 5 6.5C5 6.22386 4.77614 6 4.5 6ZM6 4.5C6 4.22386 6.22386 4 6.5 4C6.77614 4 7 4.22386 7 4.5C7 4.77614 6.77614 5 6.5 5C6.22386 5 6 4.77614 6 4.5ZM0 2.5C0 1.11929 1.11929 0 2.5 0H7.5C8.88071 0 10 1.11929 10 2.5V7.5C10 8.88071 8.88071 10 7.5 10H2.5C1.11929 10 0 8.88071 0 7.5V2.5ZM2.5 1C1.84689 1 1.29127 1.4174 1.08535 2H8.91465C8.70873 1.4174 8.15311 1 7.5 1H2.5ZM9 3H1V7.5C1 8.32843 1.67157 9 2.5 9H7.5C8.32843 9 9 8.32843 9 7.5V3Z" />
  </svg>
);

export default Save;

import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import Icon from 'types/Icon';

const { ON_SECONDARY } = colors;
const { ICON_ARROW_UP_DOWN } = ReactTestingLibraryDataProperties;

const ArrowUpDown = ({ color = ON_SECONDARY }: Icon): JSX.Element => (
  <svg
    data-testid={ICON_ARROW_UP_DOWN}
    aria-hidden="true"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M3.64016 1.60983C3.49372 1.46339 3.25628 1.46339 3.10984 1.60983L0.859835 3.85984C0.713388 4.00628 0.713388 4.24372 0.859835 4.39016C1.00628 4.53661 1.24372 4.53661 1.39017 4.39016L3 2.78033V10.125C3 10.3321 3.16789 10.5 3.375 10.5C3.58211 10.5 3.75 10.3321 3.75 10.125V2.78033L5.35984 4.39016C5.50628 4.53661 5.74372 4.53661 5.89016 4.39016C6.03661 4.24372 6.03661 4.00628 5.89016 3.85984L3.64016 1.60983ZM8.36436 10.3946C8.50971 10.5351 8.74029 10.5351 8.88564 10.3946L11.1356 8.21954C11.2845 8.0756 11.2886 7.83819 11.1446 7.68929C11.0007 7.54038 10.7633 7.53636 10.6144 7.68031L9 9.24091L9 1.87507C9 1.66797 8.83211 1.50007 8.625 1.50007C8.4179 1.50007 8.25 1.66797 8.25 1.87507L8.25 9.24091L6.63564 7.68031C6.48673 7.53636 6.24933 7.54038 6.10538 7.68929C5.96144 7.83819 5.96546 8.0756 6.11436 8.21954L8.36436 10.3946Z"
      fill={color}
    />
  </svg>
);

export default ArrowUpDown;

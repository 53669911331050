import { OrganizationV4Settings } from '@annaliseai/api-specifications';
import client from 'api/client';
import endpoints from 'api/endpoints';

const getOrgSettings = async (): Promise<OrganizationV4Settings> => {
  const type = 'organization';
  const { settings } = endpoints.v1;
  const path = settings.getPath({ type });

  const {
    data: { data },
  } = await client.get(path);

  return data as OrganizationV4Settings;
};

export default getOrgSettings;

import IconWrapper from '@annaliseai/icon-wrapper';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import colors from 'constants/colors';
import cxrSampleInfoItems from 'constants/cxrSampleInfoItems';
import { PORTRAIT } from 'constants/orientation';
import { BREAK_POINTS } from 'constants/sizes';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';
import { getRemainingFreeStudies, MAX_FREE_STUDIES } from 'helpers/limitationHelper';
import { Heading, SubHeading, TextContainer, TextGroup } from 'layouts/ResponsiveContainers';

const { SAMPLE_SCREEN_LARGE_MIN_WIDTH } = BREAK_POINTS;
const { UPLOAD_INFORMATION, UPLOAD_INFORMATION_ITEM } = ReactTestingLibraryDataProperties;
const { ON_SECONDARY } = colors;

const InfoGroup = styled.div`
  margin-left: 1rem;
  width: 100%;
  max-width: 19rem;

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    max-width: 33rem;
  }
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 1 auto;
  svg {
    font-size: 2.25rem;
    margin: 0 auto;
  }
`;

const InfoItem = styled.div``;

const Title = styled.div`
  margin: 1.1rem 0 0.4rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.625rem;
  }
`;

const TitleText = styled(SubHeading)`
  font-size: 1.1rem;
  margin-left: 0.5rem;
`;

const Description = styled.div`
  color: ${ON_SECONDARY};
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.125rem;
  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

const TextContainerStyled = styled(TextContainer)`
  margin: 0 0 0.4rem 0;
`;

const HeadingStyled = styled(Heading)`
  margin: 0 0 1rem 0;
  max-width: 18rem;
  font-size: 1.5rem;
  line-height: 1.93rem;

  @media (min-width: ${SAMPLE_SCREEN_LARGE_MIN_WIDTH}px) {
    max-width: 32rem;
  }
`;

const TextGroupStyled = styled(TextGroup)`
  margin: 0;
`;

const CxrSampleInfo: FC = () => {
  const isPortrait = useMediaQuery({ orientation: PORTRAIT });
  const isLargeScreenWidth = useMediaQuery({
    minWidth: SAMPLE_SCREEN_LARGE_MIN_WIDTH,
  });

  const zeroFreeStudyLeft = getRemainingFreeStudies() === 0;

  return (
    <InfoGroup>
      <TextContainerStyled isPortrait={isPortrait}>
        {zeroFreeStudyLeft ? (
          <HeadingStyled>{`Sorry you have exceeded the ${MAX_FREE_STUDIES} studies per week limit.`}</HeadingStyled>
        ) : (
          <HeadingStyled>{`Upload your own images. ${MAX_FREE_STUDIES} studies per week.`}</HeadingStyled>
        )}
        <TextGroupStyled>
          <Description>
            Upload your own studies to test specific findings and see how the comprehensive Annalise CXR can work for
            you. Minimum FRONT image needed. DICOM, JPEG or PNG images supported.
          </Description>
        </TextGroupStyled>
      </TextContainerStyled>
      <Panel data-testid={UPLOAD_INFORMATION}>
        {cxrSampleInfoItems.map((item, key) => {
          const { title, description, icon } = item;
          return (
            <InfoItem data-testid={UPLOAD_INFORMATION_ITEM} key={key}>
              <Title>
                <IconWrapper fill={ON_SECONDARY} svg={icon} size={isLargeScreenWidth ? 2 : 1.4} />
                <TitleText>{title}</TitleText>
              </Title>
              <Description>{description}</Description>
            </InfoItem>
          );
        })}
      </Panel>
    </InfoGroup>
  );
};

export default CxrSampleInfo;

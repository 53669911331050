import { PostFilterStudiesV2Res } from '@annaliseai/api-specifications';
import log from 'loglevel';
import client from 'api/client';
import endpoints from 'api/endpoints';
import configuration from 'configuration';
import SigningHeaders from 'enums/SigningHeaders';

const { X_ANNALISE_AI_USERNAME } = SigningHeaders;

const postStudiesFilter = async (accessionNumber: string, studyAccessor?: string): Promise<PostFilterStudiesV2Res> => {
  const { isViewer } = configuration;
  const { studiesFilter } = endpoints.v2;
  const path = studiesFilter.getPath();
  const body = { accessionNumber };

  const { data } = isViewer
    ? await client.post(path, body, { headers: { [X_ANNALISE_AI_USERNAME]: studyAccessor } })
    : await client.post(path, body);
  log.debug('received postStudiesFilter response=', data);

  return data;
};

export default postStudiesFilter;

import styled from 'styled-components';
import colors from 'constants/colors';

const { ON_NEUTRAL_BACKGROUND, ON_PRIMARY, PRIMARY } = colors;
const TransparentButton = styled.button`
  padding: 0 2rem;
  background-color: #d6cfef;
  border-color: ${ON_NEUTRAL_BACKGROUND};
  color: ${PRIMARY};
  border: 1px solid ${PRIMARY};
  border-radius: 2.25rem;
  outline: none;
  text-align: center;
  box-shadow: none;
  height: 100%;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
    border-color: ${ON_NEUTRAL_BACKGROUND};
    color: ${ON_NEUTRAL_BACKGROUND};
  }

  &:focus {
    outline: none;
    border: 1px solid ${PRIMARY};
  }

  &:active {
    background-color: #54368d;
    color: white;
  }

  &:hover:not(:active):not([disabled]) {
    background-color: ${ON_PRIMARY};
    color: ${PRIMARY};
    outline: none;
  }
`;

export default TransparentButton;

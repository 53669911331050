import { Circle20Filled, CheckmarkCircle24Filled, Delete20Regular } from '@fluentui/react-icons';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const {
  DIALOG_HEADER_ICON: DIALOG_HEADER_ICON_TEST_ID,
  DIALOG_FOOTER_LOADING_ICON,
  DIALOG_FOOTER_SUCCESS_ICON,
} = ReactTestingLibraryDataProperties;

export const BUTTON_TEXT_OK = 'OK';
export const BUTTON_TEXT_CANCEL = 'Cancel';
export const BUTTON_TEXT_DELETE = 'Delete';
export const BUTTON_TEXT_OPENER_TITLE = 'Delete study';
export const BUTTON_TEXT_OPENER_ICON = { svg: <Delete20Regular />, testId: DIALOG_HEADER_ICON_TEST_ID };

export const DIALOG_TEXT_CONFIRMATION = "You're about to delete this study. Are you sure you want to proceed?";
export const DIALOG_TEXT_NAME = 'Name:';
export const DIALOG_TEXT_ACC = 'ACC:';
export const DIALOG_TEXT_ERROR = 'Error code:';

export const DIALOG_TEXTAREA_PLACEHOLDER = 'Reason for the deletion (optional)';
export const DIALOG_TEXTAREA_MAX_CHARS = 256;

export const DIALOG_HEADER_ICON = { svg: <Delete20Regular /> };
export const DIALOG_HEADER_TEXT = 'Delete';

export const DIALOG_FOOTER_ICON_LOADING = { size: 1.8, svg: <Circle20Filled />, testId: DIALOG_FOOTER_LOADING_ICON };
export const DIALOG_FOOTER_ICON_SUCCESS = {
  size: 1.8,
  svg: <CheckmarkCircle24Filled />,
  testId: DIALOG_FOOTER_SUCCESS_ICON,
};
export const DIALOG_FOOTER_PROCESSING = 'Processing delete request...';
export const DIALOG_FOOTER_SUCCESS = 'Delete successful.';

export const DIALOG_SUCCESS_CLOSE_DELAY = 1800;

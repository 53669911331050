import styled from 'styled-components';
import colors from 'constants/colors';
import { APP } from 'constants/sizes';

const { DISCLAIMER_HEIGHT } = APP;
const { ON_NEUTRAL_BACKGROUND } = colors;

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  height: ${DISCLAIMER_HEIGHT}rem;
  color: ${ON_NEUTRAL_BACKGROUND};
  gap: 0.25rem;
`;

export const TextContainer = styled.div`
  display: flex;
  align-self: center;
  font-size: 0.63em;
  letter-spacing: 0.03em;
  font-weight: 300;
`;

import { size } from '@annaliseai/anna-design-tokens';
import styled from 'styled-components';
import colors from 'constants/colors';

const { SECONDARY_CONTAINER, ON_SECONDARY, ON_NEUTRAL_BACKGROUND, ON_PRIMARY } = colors;
const { SPACING_04 } = size;

export const ModalTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  text-align: left;
`;

export const ModalSubtitle = styled.h2`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.25em 0;
  text-align: left;
  color: ${ON_SECONDARY};
`;

export const AboutModalSubheader = styled.h2`
  font-size: 0.6rem;
  font-weight: 600;
  margin: 0.5em 0;
  text-align: left;
  color: ${ON_NEUTRAL_BACKGROUND};
  text-transform: uppercase;
  padding-top: 0.75rem;
`;

export const AboutContentWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.25em 0;
  text-align: left;
  color: ${ON_PRIMARY};
`;

export const AboutModalInfoPanel = styled.div`
  background-color: ${SECONDARY_CONTAINER};
  padding: 0.75rem 1.125rem;
  margin-right: 1.5rem;
  margin-top: 1.25rem;
`;

export const AboutIcon = styled.span`
  margin-right: 0.5rem;
`;

export const AboutLink = styled.a`
  display: flex;
  align-items: center;
`;

export const AboutLogosWrapper = styled.span`
  display: flex;
  gap: ${SPACING_04}px;
`;

import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AboutModalSubheader,
  AboutContentWrapper,
  AboutLink,
  AboutIcon,
} from 'components/Menu/MenuLink/AboutModal/AboutModal.styles';
import { DOCUMENTATION_SUBHEADER, documentationItems } from 'components/Menu/MenuLink/AboutModal/constants';
import Expand from 'icons/Expand';
import Paper from 'icons/Paper';
import { selectDocsManifest } from 'selectors/settings/selectSettings';
import { settingsActions } from 'slices/settingsSlice';

const DocumentationPanel = (): ReactElement => {
  const dispatch = useDispatch();
  const docsManifest = useSelector(selectDocsManifest);

  useEffect(() => {
    !docsManifest && dispatch(settingsActions.fetchDocsManifest());
  }, [dispatch, docsManifest]);

  return (
    <>
      <AboutModalSubheader>{DOCUMENTATION_SUBHEADER}</AboutModalSubheader>
      {documentationItems.map(({ key, link, text }) => (
        <AboutContentWrapper key={key}>
          <AboutLink href={docsManifest?.[key] ? docsManifest[key] : link} target="_blank">
            <AboutIcon>{docsManifest?.[key] ? <Paper /> : <Expand />}</AboutIcon>
            {text}
          </AboutLink>
        </AboutContentWrapper>
      ))}
    </>
  );
};

export default DocumentationPanel;

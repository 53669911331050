import { ReactComponent as PersonLightbulbRegular } from '@fluentui/svg-icons/icons/person_lightbulb_20_regular.svg';
import React, { FC } from 'react';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { ICON_ONBOARDING_EXPLAIN } = ReactTestingLibraryDataProperties;
const { ON_PRIMARY } = colors;

const OnboardExplain: FC = () => (
  <PersonLightbulbRegular width={32} height={32} fill={ON_PRIMARY} data-testid={ICON_ONBOARDING_EXPLAIN} />
);

export default OnboardExplain;

import React, { FC } from 'react';
import styled, { DataAttributes } from 'styled-components';
import colors from 'constants/colors';
import ReactTestingLibraryDataProperties from 'enums/ReactTestingLibraryDataProperties';

const { PROGRESS_BAR, PROGRESS_BAR__BAR } = ReactTestingLibraryDataProperties;
const { PRIMARY } = colors;

const Container = styled.div.attrs<DataAttributes>({ 'data-testid': PROGRESS_BAR })`
  height: 2rem;
  width: 100%;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
  background-color: #edf2f7;
  border-radius: 9999px;
  overflow: hidden;
  position: absolute;
`;

type BarProps = {
  progress: number;
};

const Bar = styled.div.attrs<DataAttributes>({ 'data-testid': PROGRESS_BAR__BAR })<BarProps>`
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 1000ms;
  height: 100%;
  background-color: ${PRIMARY};
  position: relative;
  border-radius: 9999px;
  ${({ progress }) => `width: ${progress}%`}
`;

type ProgressBarProps = {
  progress: number;
};

const ProgressBar: FC<ProgressBarProps> = ({ progress }) => (
  <Container>
    <Background />
    <Bar progress={progress} />
  </Container>
);

export default ProgressBar;
